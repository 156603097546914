import React, { useState, useEffect } from 'react';
import './style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faClock, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

function TimeDate() {
    const [currentDate, setCurrentDate] = useState(new Date().toLocaleDateString());
    const [currentTime, setCurrentTime] = useState(new Date().toLocaleTimeString());

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentDate(new Date().toLocaleDateString());
            setCurrentTime(new Date().toLocaleTimeString());
        }, 1000);

        return () => clearInterval(timer);
    }, []);
    return (
        <div className="Container">
            <div className="DateTime">
                <div className="Date">
                    <FontAwesomeIcon className="icon" icon={faCalendarAlt} />
                    <span>{currentDate}</span>
                </div>
                <div className="Time">
                    <FontAwesomeIcon className="icon" icon={faClock} />
                    <span>{currentTime}</span>
                </div>
            </div>
        </div>
    );
}

export default TimeDate;
