import React, { useState, useCallback } from 'react';
import { Button, TextField, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import 'tailwindcss/tailwind.css';

const Cart = ({ item, removeItem, submitOrder, increaseQuantity, decreaseQuantity, removeIngredient }) => {
  const [comments, setComments] = useState(Array(item.length).fill(''));

  const totalPrice = item.reduce((total, currentItem) => {
    const itemTotal = (currentItem.price || 0) * (currentItem.quantity || 1);
    return total + itemTotal;
  }, 0);

  const handleCommentChange = useCallback((index, newComment) => {
    setComments(prevComments => {
      const newComments = [...prevComments];
      newComments[index] = newComment || '';
      return newComments;
    });
  }, []);

  const handleOrderSubmit = useCallback(() => {
    const orderData = item.map((currentItem, index) => {
      const itemTotalPrice = (currentItem.price || 0) * (currentItem.quantity || 1);
      const updatedItem = {
        ...currentItem,
        totalPrice: itemTotalPrice,
        comment: comments[index] || '',
        quantity: currentItem.quantity || 1  // Ensure quantity is included
      };
      return updatedItem;
    });

    submitOrder(orderData, totalPrice.toFixed(2));
    removeItem(-1);
  }, [submitOrder, item, removeItem, comments, totalPrice]);

  return (
    <div className="bg-white rounded-lg shadow-lg flex flex-col w-full max-w-md mx-auto lg:mx-0 h-full">
      {/* Cart Header */}
      <div className="sticky top-0 bg-white z-10 p-4 border-b border-gray-200">
        <h2 className="text-2xl font-bold text-red-500">Your Cart</h2>
        <p className="text-gray-500">Total items: {item.length}</p>
      </div>

      {/* Cart Items */}
      <div className="flex-1 overflow-y-auto p-4 space-y-4">
        {item.map((currentItem, index) => (
          <div key={index} className="flex justify-between items-center">
            <div className="flex items-center gap-4">
              <img
                alt="Menu Item"
                className="rounded-md"
                height="64"
                src={currentItem.imageUrl || '/placeholder.svg'}
                style={{ aspectRatio: "1 / 1", objectFit: "cover" }}
                width="64"
              />
              <div>
                <h3 className="text-lg font-bold">{currentItem.itemName}</h3>
                <p className="text-gray-500">Quantity: {currentItem.quantity}</p>
                <div className="flex items-center gap-2 mt-2">
                  <TextField
                    className="w-full"
                    placeholder="Add comment"
                    value={comments[index]}
                    onChange={event => handleCommentChange(index, event.target.value)}
                    type="text"
                    variant="outlined"
                    size="small"
                  />
                  <IconButton
                    className="text-red-500"
                    size="small"
                    onClick={() => removeItem(index)}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
            </div>
            <span className="text-lg font-bold text-red-500">€{currentItem.price}</span>
          </div>
        ))}
      </div>

      {/* Cart Footer */}
      <div className="sticky bottom-0 bg-white z-10 p-4 border-t border-gray-200">
        <div className="flex justify-between items-center mb-4">
          <span className="font-bold">Total:</span>
          <span className="text-lg font-bold text-red-500">€{totalPrice.toFixed(2)}</span>
        </div>
        <div className="flex flex-col sm:flex-row justify-between items-center gap-4">
          <Button className="bg-red-500 text-white hover:bg-red-600 flex-1 sm:flex-none" onClick={handleOrderSubmit}>
            Place Order
          </Button>
          <Button className="bg-red-500 text-white px-3 py-1 rounded-full text-sm font-bold flex items-center justify-center" onClick={() => removeItem(-1)}>
            Remove All
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Cart;
