import React, { useState, useContext } from "react";
import { themeSettings } from '../../theme';
import { getApps, initializeApp } from "firebase/app";
import Snackbar from "../../components/Snackbar/Snackbar";
import Annimation from '../../components/LoadingAnnimation/Annimation';
import { Button, Box, Typography, Container, Grid, Link, TextField } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ColorModeContext } from '../../theme';


import "../style.css";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

function Signup() {


  const [adminName, setAdminName] = useState("");
  const [restaurantName, setRestaurantName] = useState("");
  const [emailAdmin, setAdminEmail] = useState("");
  const [passwordAdmin, setPasswordAdmin] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [userId, setUserId] = useState("");
  const [showSubscriptionButton, setShowSubscriptionButton] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [loading, setLoading] = useState(false);


  const { colorMode } = useContext(ColorModeContext);
  // Create a theme instance using the color mode
  const theme = createTheme(themeSettings(colorMode));


  if (!getApps().length) {
    initializeApp(firebaseConfig);
  }


  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      // Make the fetch request and await its response
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/registerNewRestaurant`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          adminName: adminName,
          restaurantName: restaurantName,
          phoneNumber: phoneNumber,
          emailAdmin: emailAdmin,
          passwordAdmin: passwordAdmin,
        }),
      });

      // Await the parsing of the JSON response
      const data = await response.json();

      if (response.ok) {
        console.log("Success:", data.message);
        setSnackbarMessage("Thank you for registering! Please complete your subscription.");
        setOpenSnackbar(true);
        setUserId(data); // This should be corrected as well
        setLoading(false);
        setShowSubscriptionButton(true);
      } else {
        console.error("Error:", data);
        setSnackbarMessage("Registration failed. Please try again.");

        setOpenSnackbar(true);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error.message);
      setSnackbarMessage("Registration failed. Please try again.");
      setOpenSnackbar(true);
      setLoading(false);
    }
  };

  const handleSubscription = async () => {
    setLoading(true);
    // Assuming userId is available after successful registration
    // Call your backend to create a Stripe Checkout session
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/create-checkout-session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: userId, // Use the state variable or however you store the userId
        // Include any other relevant data for creating the session
      }),
    });

    const { url } = await response.json();
    if (url) {
      // Redirect to Stripe Checkout
      window.location.href = url;
    }
    setLoading(false);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <Grid item xs={false} sm={4} md={7} sx={{
          backgroundImage: 'url(https://source.unsplash.com/random)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) => t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }} />
        <Grid item xs={12} sm={8} md={5} component={Box} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <Box sx={{ position: 'absolute', top: 16, right: 16 }}>
            <Button color="primary" href="/login">Have an account?</Button>
          </Box>
          <Container component="main" maxWidth="xs">
            <Box sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
              <Typography component="h1" variant="h1" style={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
                OrderOps
              </Typography>
              <Typography component="h1" variant="h5">
                Create your account
              </Typography>
              <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="adminName"
                  label="Admin Name"
                  name="adminName"
                  autoComplete="admin-name"
                  autoFocus
                  value={adminName}
                  onChange={(e) => setAdminName(e.target.value)}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="restaurantName"
                  label="Restaurant Name"
                  name="restaurantName"
                  autoComplete="restaurant-name"
                  value={restaurantName}
                  onChange={(e) => setRestaurantName(e.target.value)}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="emailAdmin"
                  label="Email Address"
                  name="emailAdmin"
                  autoComplete="email"
                  value={emailAdmin}
                  onChange={(e) => setAdminEmail(e.target.value)}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="phoneNumber"
                  label="Phone Number"
                  name="phoneNumber"
                  autoComplete="tel"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="passwordAdmin"
                  label="Password"
                  type="password"
                  autoComplete="new-password"
                  value={passwordAdmin}
                  onChange={(e) => setPasswordAdmin(e.target.value)}
                />
                <Typography variant="caption" display="block" gutterBottom align="center" sx={{ mt: 2 }}>
                  By clicking continue, you agree to our
                  <Link href="/terms-of-service" color="inherit"> Terms of Service</Link> and
                  <Link href="/privacy-policy" color="inherit"> Privacy Policy</Link>.
                </Typography>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Register
                </Button>
                {showSubscriptionButton && (
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                    onClick={handleSubscription}
                  >
                    Complete Subscription
                  </Button>
                )}
              </Box>
            </Box>
          </Container>
          <Annimation loading={loading} setLoading={setLoading} />
          {openSnackbar && (
            <Snackbar
              open={openSnackbar}
              autoHideDuration={6000}
              onClose={handleSnackbarClose}
              message={snackbarMessage}
            />
          )}
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}


export default Signup;