import { getAuth } from "firebase/auth";
import { initializeApp, getApps } from "firebase/app";
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import React, { useState, useEffect, useCallback } from 'react';
import MenuItemCard from '../../models/MenuItems';
import OrderCard from '../../models/Order/Order.js';
import ItemSectionCard from '../../models/ItemSection';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { faChartLine } from '@fortawesome/free-solid-svg-icons';

import './AdminDashboard.css';
import { tokens } from '../../theme.js';
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import Header from "../../components/Header/index.js";
import LineChart from "../../components/Charts/LineChart.js";
import BarChart from "../../components/Charts/BarChar.js";
import StatBox from '../../components/DesignBox/StatBox.js';
import ProgressCircle from '../../components/ProgressCircle/index.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEuroSign, faUtensils } from '@fortawesome/free-solid-svg-icons';
import SideBar1 from "../../components/SideBarAdmin/Sidebar.js";
import { faShoppingCart, faMoneyBillWave, faTags } from '@fortawesome/free-solid-svg-icons';

// Firebase config moved outside of the component to avoid re-initialization
const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

if (!getApps().length) {
    initializeApp(firebaseConfig);
}

const storage = getStorage();

function DashboardAdmin() {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [file, setFile] = useState(null);
    const [userId, setUserId] = useState(null);
    const [message, setMessage] = useState('');
    const [quantity, setQuantity] = useState('');
    const [tableID, setTableID] = useState('');
    const [status, setStatus] = useState('');
    const [aggregatedOrdersData, setAggregatedOrdersData] = useState([]);
    const [menuItems, setMenuItems] = useState([]);
    const [totalOrders, setTotalOrders] = useState(0);
    const [totalRevenue, setTotalRevenue] = useState(0);
    const [menuSections, setMenuSection] = useState([]);
    const [queriedItemSection, setQueriedItemSection] = useState('');
    const [orders, setOrders] = useState([]);
    const [analyzeOrdersData, setAnalyzeOrdersData] = useState({ salesAnalysis: [], itemPerformance: [], revenueTrends: [] });
    const [accountInfo, setAccountInfo] = useState(null);

    const auth = getAuth();

    // Debounced fetch functions
    const fetchMenuItems = useCallback(async (userId, queriedItemSection) => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/menuItems/${userId}/${queriedItemSection}`);
        const menuItemsData = await response.json();

        const menuItemsWithImages = await Promise.all(
            menuItemsData.map(async (menuItem) => {
                try {
                    const imageUrl = await getDownloadURL(ref(storage, menuItem.imageUrl));
                    menuItem.imageUrl = imageUrl;
                } catch (error) {
                    console.error('Error getting download URL:', error);
                    menuItem.imageUrl = null;
                }
                return menuItem;
            })
        );

        setMenuItems(menuItemsWithImages);
    }, []);

    const fetchOrders = useCallback(async (userId) => {
        const today = new URLSearchParams({ today: 'true' }).toString();
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/orderItems/${userId}?${today}`);
        const orderItems = await response.json() || [];
        const totalRevenueToday = orderItems.reduce((acc, order) => acc + (order.totalPrice || 0), 0);
        const aggregatedData = aggregateOrdersDataForBarChart(orderItems);

        setAggregatedOrdersData(aggregatedData);
        setOrders(orderItems);
        setTotalOrders(orderItems.length);
        setTotalRevenue(totalRevenueToday);
    }, []);

    const fetchAccountInfo = useCallback(async (userId) => {
        const accountInfo = await fetch(`${process.env.REACT_APP_BACKEND_URL}/accountInfo/${userId}`);
        const accountData = await accountInfo.json();

        if (accountData.imageUrl) {
            const imageUrl = await getDownloadURL(ref(storage, accountData.imageUrl));
            accountData.imageUrl = imageUrl;
        }
        setAccountInfo(accountData);
    }, []);

    const fetchMenuSections = useCallback(async (userId) => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/itemSections/${userId}`);
        const menuSections = await response.json();
        setMenuSection(menuSections);
    }, []);

    const fetchAnalyzeOrders = useCallback(async (userId) => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/analyzeOrders/${userId}`);
        const analyzeOrdersData = await response.json();
        setAnalyzeOrdersData(analyzeOrdersData);
    }, []);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                setUserId(user.uid);
                fetchOrders(user.uid);
                fetchMenuSections(user.uid);
                fetchAnalyzeOrders(user.uid);
                fetchAccountInfo(user.uid);
            } else {
                console.log("User logged out!");
                setMessage("Please Login!");
            }
        });

        return () => {
            unsubscribe();
        };
    }, [auth, fetchOrders, fetchMenuSections, fetchAnalyzeOrders, fetchAccountInfo]);

    useEffect(() => {
        if (userId) {
            fetchMenuItems(userId, queriedItemSection);
        }
    }, [userId, queriedItemSection, fetchMenuItems]);

    useEffect(() => {
        console.log(orders);
        console.log("analyzeOrdersData: ", analyzeOrdersData);
    }, [menuItems, orders, menuSections, analyzeOrdersData]);

    const registerNewTable = async (event) => {
        try {
            await fetch(`${process.env.REACT_APP_BACKEND_URL}/registerTable`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId }),
            });
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const generateToken = async (event) => {
        try {
            await fetch(`${process.env.REACT_APP_BACKEND_URL}/generateToken`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId }),
            });
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const queryToken = async (event) => {
        const token = "feebac21bebad2f3ef509dddbee0f5b7";
        try {
            await fetch(`${process.env.REACT_APP_BACKEND_URL}/qrcode`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token }),
            });
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleClick11 = (id) => {
        setQueriedItemSection(id);
        console.log("Clicked chip:", id);
    };

    if (!analyzeOrdersData) {
        return <div>Loading analyzeOrdersData...</div>;
    }


    // Calculations
    const { salesAnalysis, itemPerformance, revenueTrends } = analyzeOrdersData;
    const totalSales = Array.isArray(salesAnalysis)
        ? salesAnalysis.reduce((acc, cur) => acc + cur.totalQuantity, 0).toFixed(2)
        : "0.00";

    const costs = Array.isArray(revenueTrends)
        ? revenueTrends.reduce((a, b) => a + b, 0).toFixed(2)
        : "0.00";

    const calculatePeakHours = (orders) => {
        const hourTally = {};
        orders.forEach(order => {
            const createdAt = new Date(order.createdAt._seconds * 1000);
            const hour = createdAt.getHours();
            hourTally[hour] = (hourTally[hour] || 0) + 1;
        });
        const sortedHours = Object.entries(hourTally).sort((a, b) => b[1] - a[1]);
        if (sortedHours.length > 0) {
            const peakHour = sortedHours[0][0];
            const peakOrders = sortedHours[0][1];
            const peakHours = sortedHours.filter(([hour, count]) => count === peakOrders);
            if (peakHours.length > 1) {
                const earliestPeakHour = peakHours[0][0];
                const latestPeakHour = peakHours[peakHours.length - 1][0];
                return `${earliestPeakHour}:00 - ${parseInt(latestPeakHour) + 1}:00`;
            } else {
                return `${peakHour}:00 - ${parseInt(peakHour) + 1}:00`;
            }
        } else {
            return null;
        }
    };

    const peakHourRange = calculatePeakHours(orders);

    const getHourlyData = (orders) => {
        const hoursMap = new Array(24).fill(0);
        orders.forEach(order => {
            const date = new Date(order.createdAt._seconds * 1000);
            const hour = date.getHours();
            hoursMap[hour]++;
        });
        return hoursMap.map((count, hour) => ({ x: hour.toString(), y: count }));
    };

    const peakHoursChartData = getHourlyData(orders);

    const totalItemsSold = orders.reduce((total, order) => {
        console.log("Processing order:", order);
        const itemsCount = order.items.reduce((count, item) => {
            console.log("Processing item:", item);
            const quantity = Number(item.quantity); // Ensure quantity is a number
            console.log("Item quantity (as number):", quantity);
            if (isNaN(quantity)) {
                console.error("Found NaN quantity for item:", item);
                return count;
            }
            return count + quantity;
        }, 0);
        console.log("Items count for this order:", itemsCount);
        return total + itemsCount;
    }, 0);
    console.log("Total items sold:", totalItemsSold);


    const formatOrderTime = (seconds) => {
        const orderDate = new Date(seconds * 1000);
        return orderDate.toLocaleTimeString('en-GB', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        });
    };

    const itemCounts = {};
    orders.forEach(order => {
        order.items.forEach(item => {
            itemCounts[item.itemName] = itemCounts[item.itemName] || 0;
            itemCounts[item.itemName] += item.quantity;
        });
    });

    const mostPopularItem = Object.keys(itemCounts).reduce((a, b) => itemCounts[a] > itemCounts[b] ? a : b, '');
    const mostPopularItemSoldUnits = itemCounts[mostPopularItem] || 0;

    const calculateProgress = (current, target) => {
        if (target === 0) return 0;
        return ((current / target) * 100).toFixed(2);
    };

    const calculateIncrease = (data) => {
        // Check if data is neither undefined nor null and is an object
        if (data && typeof data === 'object') {
            const dataValues = Array.isArray(data) ? data : Object.values(data);
            if (dataValues.length < 2) return 'N/A';
            const latest = dataValues[dataValues.length - 1];
            const previous = dataValues[dataValues.length - 2];
            const increase = ((latest.totalRevenue - previous.totalRevenue) / previous.totalRevenue) * 100;
            return increase.toFixed(2) + '%';
        }
        return 'N/A'; // Return 'N/A' if data is invalid
    };


    const ordersWithMostPopularItem = orders.filter(order =>
        order.items.some(item => item.itemName === mostPopularItem)
    ).length;

    const progressMostPopularItem = ((ordersWithMostPopularItem / totalOrders) * 100).toFixed(0);

    const itemQuantities = {};
    orders.forEach(order => {
        order.items.forEach(item => {
            if (itemQuantities[item.itemName]) {
                itemQuantities[item.itemName] += item.quantity;
            } else {
                itemQuantities[item.itemName] = item.quantity;
            }
        });
    });

    const totalQuantitySold = Object.values(itemQuantities).reduce((total, current) => total + current, 0);

    const itemRatios = Object.keys(itemQuantities).map(itemName => ({
        name: itemName,
        ratio: (itemQuantities[itemName] / totalQuantitySold) * 100,
    }));

    const calculateBackground = (itemRatios, colors) => {
        const sortedRatios = itemRatios.sort((a, b) => b.ratio - a.ratio);
        let backgroundString = `conic-gradient(`;
        let accumulatedPercentage = 0;
        sortedRatios.forEach((item, index) => {
            const color = colors[index % colors.length];
            const nextStop = accumulatedPercentage + item.ratio;
            backgroundString += `${color} ${accumulatedPercentage}% ${nextStop}%`;
            accumulatedPercentage = nextStop;
            if (index < sortedRatios.length - 1) backgroundString += ', ';
        });
        backgroundString += ')';
        return backgroundString;
    };

    const segmentColors = ['#FF6347', '#4682B4', '#32CD32', '#FFD700', '#6A5ACD', '#FF69B4'];
    const background = calculateBackground(itemRatios, segmentColors);

    const chartData = [
        {
            "id": "Pizza mit Recola ",
            "color": "#fff",
            "data": [
                {
                    "x": "Pizza mit Recola ",
                    "y": 2
                }
            ]
        },
        {
            "id": "Pizza mit Ananas ",
            "color": "#fff",
            "data": [
                {
                    "x": "Pizza mit Ananas ",
                    "y": 5
                }
            ]
        },
        {
            "id": "Burger",
            "color": "#fff",
            "data": [
                {
                    "x": "Burger",
                    "y": 4
                }
            ]
        }
    ];

    const aggregateOrdersDataForBarChart = (orders) => {
        const itemSectionsSum = {};
        orders.forEach((order) => {
            order.items.forEach((item) => {
                if (!itemSectionsSum[item.itemSection]) {
                    itemSectionsSum[item.itemSection] = 0;
                }
                itemSectionsSum[item.itemSection] += item.quantity;
            });
        });
        return Object.entries(itemSectionsSum).map(([itemSection, quantity]) => ({
            itemSection,
            quantity,
        }));
    };

    const buttonContainerStyles = {
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        alignItems: { xs: 'center', sm: 'flex-end' },
        justifyContent: 'space-between',
    };


    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                width: '100%',
                height: '100vh',
            }}
        >
            <SideBar1 accountInfo={accountInfo} />
            <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
                <Box width="98%" m="20px auto">
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            mb: 2,
                        }}
                    >
                        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />
                        <Button
                            sx={{
                                backgroundColor: colors.blueAccent[700],
                                color: colors.grey[100],
                                fontSize: "14px",
                                fontWeight: "bold",
                                padding: "10px 20px",
                                mt: { xs: 2, sm: 0 },
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                            }}
                        >
                            <DownloadOutlinedIcon sx={{ mr: "10px", color: "#fff" }} />
                            <span style={{ color: "#fff" }}>Download Reports</span>
                        </Button>
                    </Box>
                    <Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gridAutoRows="140px"
                        gap="20px"
                    >

                        <Box
                            gridColumn={{ xs: 'span 12', sm: 'span 6', lg: 'span 3' }}
                            sx={{
                                border: `1px solid ${theme.palette.mode === 'dark' ? colors.grey[100] : colors.grey[900]}`,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "16px",
                                overflow: "hidden",
                                backgroundColor: theme.palette.background.default,
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                            }}
                        >
                            <StatBox
                                title={`${totalOrders}`}
                                subtitle="Total Orders"
                                progress={calculateProgress(totalOrders, totalOrders)}
                                increase={`${mostPopularItemSoldUnits}%`}
                                icon={<FontAwesomeIcon icon={faShoppingCart} sx={{ color: colors.primary[500], fontSize: "26px" }} />}
                            />
                        </Box>


                        <Box
                            gridColumn={{ xs: 'span 12', sm: 'span 6', lg: 'span 3' }}
                            sx={{
                                border: `1px solid ${theme.palette.mode === 'dark' ? colors.grey[100] : colors.grey[900]}`,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "16px",
                                overflow: "hidden",
                                backgroundColor: theme.palette.background.default,
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                            }}
                        >
                            <StatBox
                                title={`€${totalRevenue ? totalRevenue.toFixed(2) : '0.00'}`}
                                subtitle="Total Revenue"
                                progress={calculateProgress(totalRevenue, costs)}
                                increase={`${calculateIncrease(revenueTrends)}%`}
                                icon={<FontAwesomeIcon icon={faMoneyBillWave} sx={{ color: colors.primary[500], fontSize: "26px" }} />}
                            />
                        </Box>
                        <Box
                            gridColumn={{ xs: 'span 12', sm: 'span 6', lg: 'span 3' }}
                            sx={{
                                border: `1px solid ${theme.palette.mode === 'dark' ? colors.grey[100] : colors.grey[900]}`,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "16px",
                                overflow: "hidden",
                                backgroundColor: theme.palette.background.default,
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                            }}
                        >
                            <StatBox
                                title={`${totalItemsSold} Item`}
                                subtitle="Total sold items"
                                progress="100"
                                increase={`${totalItemsSold} Items`}
                                icon={<FontAwesomeIcon icon={faTags} sx={{ color: colors.primary[500], fontSize: "26px" }} />}
                            />
                        </Box>

                        <Box
                            gridColumn={{ xs: 'span 12', sm: 'span 6', lg: 'span 3' }}
                            sx={{
                                border: `1px solid ${theme.palette.mode === 'dark' ? colors.grey[100] : colors.grey[900]}`,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "16px",
                                overflow: "hidden",
                                backgroundColor: theme.palette.background.default,
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                            }}
                        >
                            <StatBox
                                title={mostPopularItem || "N/A"}
                                subtitle="Most Popular Item"
                                progress={progressMostPopularItem}
                                increase={`${mostPopularItemSoldUnits} stk.`}
                                icon={<FontAwesomeIcon icon={faUtensils} sx={{ color: colors.primary[500], fontSize: "26px" }} />}
                            />
                        </Box>

                        <Box
                            gridColumn={{ xs: 'span 12', lg: 'span 8' }}
                            gridRow="span 2"
                            sx={{
                                border: `1px solid ${theme.palette.mode === 'dark' ? colors.grey[100] : colors.grey[900]}`,
                                borderRadius: '16px',
                                overflow: 'hidden',
                                backgroundColor: theme.palette.background.default,
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                            }}
                        >
                            <Box mt="25px" p="0 30px" display="flex" justifyContent="space-between" alignItems="center">
                                <Box>
                                    <Typography variant="h5" fontWeight="600" color={colors.grey[100]}>
                                        Peak Visiting Hours
                                    </Typography>
                                    {
                                        peakHourRange ? (
                                            <Typography variant="h3" fontWeight="bold" color={colors.primary[500]}>
                                                {peakHourRange}
                                            </Typography>
                                        ) : (
                                            <Typography variant="h5" color={colors.grey[100]}>
                                                Not enough data to display peak hours. Generate more data.
                                            </Typography>
                                        )
                                    }
                                </Box>
                                <Box>
                                    <IconButton>
                                        <DownloadOutlinedIcon sx={{ fontSize: "26px", color: colors.primary[500] }} />
                                    </IconButton>
                                </Box>
                            </Box>
                            <Box height="250px" m="-20px 0 0 0">
                                <LineChart data={peakHoursChartData} />
                            </Box>
                        </Box>

                        <Box
                            gridColumn={{ xs: 'span 12', lg: 'span 4' }}
                            gridRow="span 2"
                            sx={{
                                border: `1px solid ${theme.palette.mode === 'dark' ? colors.grey[100] : colors.grey[900]}`,
                                overflow: 'auto',
                                borderRadius: '16px',
                                overflow: 'hidden',
                                backgroundColor: theme.palette.background.default,
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                            }}
                        >
                            <Box
                                overflow="auto"
                                sx={{
                                    maxHeight: '600px',
                                    '&::-webkit-scrollbar': {
                                        width: '6px',
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        backgroundColor: colors.primary[500],
                                        borderRadius: '16px',
                                    },
                                }}
                            >
                                {orders.length > 0 ? (
                                    orders.map((order, i) => (
                                        <Box
                                            key={`${order.id}-${i}`}
                                            display="flex"
                                            flexDirection="column"
                                            borderBottom={`4px solid ${colors.primary[500]}`}
                                            p="15px"
                                        >
                                            <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                                                <Box flex="1" pr="16px">
                                                    <Typography
                                                        color={colors.primary[500]}
                                                        variant="h5"
                                                        fontWeight="600"
                                                        sx={{ wordBreak: 'break-word' }}
                                                    >
                                                        {order.items.map((item, index) => (
                                                            <span key={index}>
                                                                {item.itemName}{index < order.items.length - 1 ? ", " : ""}
                                                            </span>
                                                        ))}
                                                    </Typography>
                                                    <Typography color={colors.grey[100]} sx={{ mt: '8px' }}>
                                                        Order ID: {order.id}
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography color={colors.grey[100]} sx={{ whiteSpace: 'nowrap', mb: '8px' }}>
                                                        Today, {formatOrderTime(order.createdAt._seconds)}
                                                    </Typography>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            backgroundColor: colors.primary[500],
                                                            p: '5px 10px',
                                                            borderRadius: '4px',
                                                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontWeight: 'bold',
                                                                color: colors.grey[100],
                                                                whiteSpace: 'nowrap',
                                                            }}
                                                        >
                                                            €{order.totalPrice.toFixed(2)}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    ))
                                ) : (
                                    <Typography color={colors.grey[100]} variant="h5" fontWeight="600" textAlign="center" p="20px">
                                        No recent orders
                                    </Typography>
                                )}
                            </Box>
                        </Box>

                        <Box
                            gridColumn={{ xs: 'span 12', lg: 'span 6' }}
                            gridRow="span 2"
                            sx={{
                                border: `1px solid ${theme.palette.mode === 'dark' ? colors.grey[100] : colors.grey[900]}`,
                                p: 3,
                                borderRadius: '16px',
                                overflow: 'hidden',
                                display: 'flex',
                                flexDirection: 'column',
                                backgroundColor: theme.palette.background.default,
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                            }}
                        >
                            <Typography variant="h5" fontWeight="600" gutterBottom color={colors.primary[500]}>
                                Sales Overview
                            </Typography>
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    overflowY: 'auto',
                                    gap: 2,
                                    p: 2,
                                    borderRadius: 1,
                                    boxShadow: 'inset 0 0 8px rgba(0,0,0,0.2)',
                                    '&::-webkit-scrollbar': {
                                        width: '6px',
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        backgroundColor: 'rgba(0,0,0,.1)',
                                        borderRadius: '16px',
                                    },
                                }}
                            >
                                {itemRatios.map((item, index) => (
                                    <Box
                                        key={item.name}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            padding: '8px',
                                            borderRadius: '4px',
                                            boxShadow: `0 2px 4px rgba(0,0,0,${index % 2 === 0 ? '0.1' : '0.05'})`,
                                            backgroundColor: theme.palette.background.default,
                                        }}
                                    >
                                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', flexShrink: 0 }}>
                                            {item.name}
                                        </Typography>
                                        <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
                                            <ProgressCircle progress={(item.ratio / 100).toString()} size="40" />
                                            <Typography sx={{ ml: 1, whiteSpace: 'nowrap' }}>
                                                {item.ratio.toFixed(2)}%
                                            </Typography>
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                        </Box>

                        <Box
                            gridColumn={{ xs: 'span 12', lg: 'span 6' }}
                            gridRow="span 2"
                            sx={{
                                border: `1px solid ${theme.palette.mode === 'dark' ? colors.grey[100] : colors.grey[900]}`,
                                borderRadius: '16px',
                                overflow: 'hidden',
                                backgroundColor: theme.palette.background.default,
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                            }}
                        >
                            <Typography
                                variant="h5"
                                fontWeight="600"
                                sx={{ padding: "30px 30px 0 30px" }}
                                color={colors.primary[500]}
                            >
                                Sales Quantity
                            </Typography>
                            <Box height="270px" mt="-30px">
                                <BarChart data={aggregatedOrdersData} />
                            </Box>
                        </Box>
                    </Box>

                    <Box sx={{ mt: 4, p: 3, backgroundColor: theme.palette.background.default, borderRadius: 2, boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' }}>
                        <Button
                            className="button-primary"
                            sx={{
                                backgroundColor: colors.blueAccent[700],
                                color: colors.grey[100],
                                fontSize: "14px",
                                fontWeight: "bold",
                                padding: "10px 20px",
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                '&:hover': {
                                    backgroundColor: colors.blueAccent[800],
                                }
                            }}
                            onClick={registerNewTable}
                        >
                            Register New Table
                        </Button>
                    </Box>
                    <Box mt={4} sx={{ backgroundColor: theme.palette.background.default, borderRadius: 2, boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', p: 3 }}>
                        <Typography variant="h4" sx={{ mb: 2, color: colors.primary[500] }}>Menu</Typography>
                        <Box mb={2}>
                            <ItemSectionCard
                                key={menuSections.id}
                                itemSections={menuSections}
                                handleClick={handleClick11}
                                queriedItemSection={queriedItemSection}
                            />
                        </Box>
                        <Box display="flex" flexWrap="wrap" gap={2}>
                            {menuItems.map((menuItem) => (
                                <MenuItemCard key={menuItem.itemID} item={menuItem} />
                            ))}
                        </Box>
                    </Box>

                    <Box sx={{ mt: 4, p: 3, backgroundColor: theme.palette.background.default, borderRadius: 2, boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' }}>
                        <Typography variant="h4" sx={{ mb: 2, color: colors.primary[500] }}>Orders</Typography>
                        <Box className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 max-h-[40rem] overflow-y-auto">
                            {orders
                                .sort((a, b) => {
                                    const dateA = new Date(a.createdAt._seconds * 1000 + a.createdAt._nanoseconds / 1000000);
                                    const dateB = new Date(b.createdAt._seconds * 1000 + b.createdAt._nanoseconds / 1000000);
                                    return dateB - dateA;
                                })
                                .map((order) => (
                                    <OrderCard key={order.id} orderData={order} />
                                ))}
                        </Box>
                    </Box>



                </Box>
            </Box>
        </Box>
    );
}

export default DashboardAdmin;
