import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { useTheme } from '@mui/material/styles';

const MyResponsiveBarChart = ({ data }) => {
  const theme = useTheme();

  return (
    <ResponsiveBar
      data={data}
      keys={['quantity']}
      indexBy="itemSection"
      margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
      padding={0.3}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={{ scheme: 'set3' }} // Using a predefined color scheme
      borderRadius={4} // Rounded bars
      borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Item Section',
        legendPosition: 'middle',
        legendOffset: 40,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Quantity',
        legendPosition: 'middle',
        legendOffset: -50,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      legends={[
        {
          dataFrom: 'keys',
          anchor: 'bottom-right',
          direction: 'column',
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      tooltip={({ id, value, color }) => (
        <strong style={{ color }}>
          {id}: {value}
        </strong>
      )}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      theme={{
        tooltip: {
          container: {
            background: theme.palette.background.paper,
            color: theme.palette.text.primary,
            fontSize: '1rem',
            borderRadius: '4px',
            boxShadow: '0 3px 6px rgba(0,0,0,0.1)',
            padding: '5px',
          },
        },
      }}
    />
  );
};

export default MyResponsiveBarChart;      