import React, { useState, useCallback, useEffect } from 'react';
import { Box, Button, TextField, useTheme, useMediaQuery } from '@mui/material';
import { getAuth } from "firebase/auth";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { tokens } from '../../theme';
import SideBar1 from '../../components/SideBarAdmin/Sidebar';
import Header from '../../components/Header';

const ManageTables = () => {
    const [tables, setTables] = useState([]);
    const [tableId, setTableId] = useState('');
    const [capacity, setCapacity] = useState('');
    const [userId, setUserId] = useState(null);
    const [accountInfo, setAccountInfo] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleAddTable = useCallback(async () => {
        if (!tableId || !capacity) return;

        const newTable = {
            tableId,
            capacity: Number(capacity),
            status: 'Available',
        };

        try {
            const storedToken = sessionStorage.getItem('firebaseIdToken');
            if (!storedToken) {
                throw new Error('No token found');
            }
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/tables/${userId}`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${storedToken}`
                },
                body: JSON.stringify(newTable),
            });
            if (!response.ok) {
                throw new Error('Failed to save table');
            }
            const savedTable = await response.json();
            setTables(prev => [...prev, savedTable]);
        } catch (error) {
            console.error('Error saving table:', error);
            setError(error.message);
        }

        setTableId('');
        setCapacity('');
    }, [tableId, capacity, userId]);

    const fetchTables = useCallback(async (userId) => {
        setLoading(true);
        setError(null);
        try {
            const storedToken = sessionStorage.getItem('firebaseIdToken');
            if (!storedToken) {
                throw new Error('No token found');
            }
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/tables/${userId}`, {
                headers: { 'Authorization': `Bearer ${storedToken}` }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch tables');
            }
            const tablesData = await response.json();
            setTables(tablesData);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                setUserId(user.uid);
                fetchTables(user.uid);
            }
        });
        return () => {
            unsubscribe();
        };
    }, [fetchTables]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                width: '100%',
                height: '100vh',
            }}
        >
            <SideBar1 accountInfo={accountInfo} />

            <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
                <Box width="98%" m="20px auto">
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            mb: 2,
                        }}
                    >
                        <Header title="REGISTER TABLES" subtitle="Welcome to your dashboard" />
                    </Box>
                    <div className="container mx-auto mt-4">
                        <div className="flex flex-col gap-4">
                            <div className="p-4 border rounded-lg shadow-lg">
                                <h2 className="text-xl font-bold mb-4">Register New Table</h2>
                                <p className="text-gray-600 mb-4">Add a new table to your restaurant management system.</p>
                                <TextField
                                    type="number"
                                    className="form-control mb-2 p-2 border rounded w-full"
                                    value={capacity}
                                    onChange={e => setCapacity(e.target.value)}
                                    placeholder="Capacity"
                                    variant="outlined"
                                    fullWidth
                                />
                                <Button
                                    className="bg-red-500 text-white py-2 px-4 rounded"
                                    onClick={handleAddTable}
                                    variant="contained"
                                    color="primary"
                                >
                                    Save Table
                                </Button>
                            </div>
                            <div className="p-4 border rounded-lg shadow-lg h-64 overflow-y-auto">
                                <h2 className="text-xl font-bold mb-4">Registered Tables</h2>
                                <p className="text-gray-600 mb-4">View and manage all tables registered for your restaurant.</p>
                                {loading ? (
                                    <p>Loading...</p>
                                ) : error ? (
                                    <p>Error: {error}</p>
                                ) : (
                                    <table className="table-auto w-full">
                                        <thead>
                                            <tr>
                                                <th className="px-4 py-2">Table ID</th>
                                                <th className="px-4 py-2">Capacity</th>
                                                <th className="px-4 py-2">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tables.map((table, index) => (
                                                <tr key={index}>
                                                    <td className="border px-4 py-2">{table.tableId}</td>
                                                    <td className="border px-4 py-2">{table.capacity}</td>
                                                    <td className="border px-4 py-2">{table.status}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        </div>
                    </div>
                </Box>
            </Box>
        </Box>
    );
};

export default ManageTables;
