import React, { useContext } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Avatar, Divider, Typography, Box, useTheme, useMediaQuery, IconButton } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import OrderIcon from '@mui/icons-material/ShoppingCart';
import Orderview from '@mui/icons-material/ViewList';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { ColorModeContext } from '../../theme.js';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import { useNavigate } from 'react-router-dom';
import { getAuth, signOut } from "firebase/auth";

const drawerWidth = 240;

const StyledDrawer = styled(Drawer)(({ theme }) => ({
    width: drawerWidth,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
        width: drawerWidth,
        boxSizing: 'border-box',
        backdropFilter: 'blur(10px)',
        borderRight: '1px solid rgba(255, 255, 255, 0.1)',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
}));

const menuItems = [
    { text: 'Home', icon: <HomeIcon />, path: '/reserve-table' },
    { text: 'Orders', icon: <OrderIcon />, path: '/orders' },
    { text: 'Order Views', icon: <Orderview />, path: '/order-views' },
];

const Sidebar = ({ accountInfo }) => {
    const theme = useTheme();
    const colorMode = useContext(ColorModeContext);
    const navigate = useNavigate();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleLogout = () => {
        const auth = getAuth();
        signOut(auth).then(() => {
            console.log("Logout successful");
            navigate('/logout');
        }).catch((error) => {
            console.error("Logout error", error);
        });
    };

    const handleNavigation = (path) => {
        navigate(path);
    };

    return (
        <div>
            {isMobile ? (
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{
                        position: 'fixed',
                        top: 16,
                        left: 16,
                        zIndex: theme.zIndex.drawer + 1,
                        display: { sm: 'none' }
                    }}
                >
                    <MenuIcon />
                </IconButton>
            ) : null}
            <StyledDrawer
                variant={isMobile ? 'temporary' : 'permanent'}
                open={isMobile ? mobileOpen : true}
                onClose={handleDrawerToggle}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        p: 2,
                        backgroundColor: theme.palette.primary.main // Use primary color for the upper part
                    }}
                >
                    <Avatar
                        component={motion.div}
                        initial={{ y: -50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: 0.2 }}
                        sx={{
                            width: 100,
                            height: 100,
                            mb: 2,
                            bgcolor: 'secondary.main',
                            boxShadow: theme.shadows[10]
                        }}
                        src={accountInfo?.imageUrl}
                    >
                        {!accountInfo?.imageUrl && <AccountCircleIcon fontSize="large" />}
                    </Avatar>
                    <Typography variant="h6" sx={{ color: 'white', fontWeight: 'bold' }}>
                        {accountInfo?.name || "Username"}
                    </Typography>
                    <motion.div
                        initial={{ scaleY: 0 }}
                        animate={{ scaleY: 1 }}
                        transition={{ delay: 0.5, duration: 0.5 }}
                        style={{ originY: 0, width: '100%', height: 4, background: theme.palette.secondary.main, position: 'absolute', bottom: 0 }}
                    />
                </Box>
                <Divider />
                <Box sx={{ backgroundColor: theme.palette.background.default, flexGrow: 1 }}>
                    <List>
                        {menuItems.map((item, index) => (
                            <ListItem
                                button
                                key={index}
                                component={motion.li}
                                whileHover={{ scale: 1.05 }}
                                onClick={() => handleNavigation(item.path)}
                                sx={{
                                    padding: isMobile ? '20px' : '10px',
                                    fontSize: isMobile ? '1.2rem' : '1rem'
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        color: theme.palette.mode === 'dark' ? 'text.primary' : 'text.secondary',
                                        minWidth: isMobile ? '50px' : '40px',
                                        fontSize: isMobile ? '1.5rem' : '1.2rem'
                                    }}
                                >
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText
                                    primary={item.text}
                                    primaryTypographyProps={{
                                        variant: isMobile ? 'h6' : 'body2',
                                        color: theme.palette.mode === 'dark' ? 'text.primary' : 'text.secondary'
                                    }}
                                />
                            </ListItem>
                        ))}
                        <ListItem
                            button
                            key="Toggle Mode"
                            onClick={colorMode.toggleColorMode}
                            component={motion.li}
                            whileHover={{ scale: 1.05 }}
                            sx={{
                                padding: isMobile ? '20px' : '10px',
                                fontSize: isMobile ? '1.2rem' : '1rem'
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    color: 'text.secondary',
                                    minWidth: isMobile ? '50px' : '40px',
                                    fontSize: isMobile ? '1.5rem' : '1.2rem'
                                }}
                            >
                                <Brightness4Icon />
                            </ListItemIcon>
                            <ListItemText
                                primary="Toggle Mode"
                                primaryTypographyProps={{
                                    variant: isMobile ? 'h6' : 'body2',
                                    color: 'text.secondary'
                                }}
                            />
                        </ListItem>
                    </List>
                </Box>
                <Divider />
                <List>
                    <ListItem
                        button
                        key="Logout"
                        onClick={handleLogout}
                        component={motion.li}
                        whileHover={{ scale: 1.05 }}
                        sx={{
                            padding: isMobile ? '20px' : '10px',
                            fontSize: isMobile ? '1.2rem' : '1rem'
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                color: 'text.secondary',
                                minWidth: isMobile ? '50px' : '40px',
                                fontSize: isMobile ? '1.5rem' : '1.2rem'
                            }}
                        >
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary="Logout"
                            primaryTypographyProps={{
                                variant: isMobile ? 'h6' : 'body2',
                                color: 'text.secondary'
                            }}
                        />
                    </ListItem>
                </List>
            </StyledDrawer>
        </div>
    );
};

export default Sidebar;
