import { AiOutlineHome } from 'react-icons/ai';
import { FiShoppingCart } from 'react-icons/fi';
import { IoMdListBox } from 'react-icons/io';
import { RiSettings5Fill } from 'react-icons/ri';
//Import light and dark mode icons
import { FaMoon, FaSun } from 'react-icons/fa';
import { BiLogOut } from 'react-icons/bi';





export const SidebarData = [
  {
    title: 'Home',
    path: '/',
    icon: <AiOutlineHome style={{ color: 'white' }} />,
    cName: 'nav-text'
  },
  {
    title: 'Orders',
    path: '/orders',
    icon: <FiShoppingCart style={{ color: 'white' }} />,
    cName: 'nav-text'
  },
  {
    title: 'orderViewPage',
    path: '/orderViewPage',
    icon: <IoMdListBox style={{ color: 'white' }} />,
    cName: 'nav-text'
  },
  {
    title: 'Settings',
    path: '/settings',
    icon: <RiSettings5Fill style={{ color: 'white' }} />,
    cName: 'nav-text'
  },
  {
    title: 'Theme',
    path: '/ThemeMode',
    icon: <FaMoon style={{ color: 'white' }} />,
    cName: 'nav-text',
    id: 'theme-button' 
  },

  {
    title: 'Logout',
    path: '/logout',
    icon: <BiLogOut style={{ color: 'white' }} />,
    cName: 'nav-text'
  },
];
