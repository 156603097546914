import React from 'react';
import Button from './Button';
import Badge from './Badge';

function SingleMenuCard({ item, onClickButton }) {
    return (
        <div className="bg-white rounded-lg shadow-md overflow-hidden dark:bg-gray-800 flex flex-col m-4">
            <img
                alt="Menu Item"
                className="w-full h-48 object-cover"
                src={item.imageUrl || "/placeholder.svg"}
            />
            <div className="p-6 flex flex-col flex-1 justify-between">
                <div className="space-y-4">
                    <div className="flex items-center justify-between">
                        <h3 className="text-xl font-bold text-gray-900 dark:text-white">{item.itemName}</h3>
                        <Badge
                            className="border-red-500 bg-white dark:bg-gray-800 px-2 py-1 text-red-500 dark:text-red-500"
                            variant="outline"
                        >
                            {item.label}
                        </Badge>
                    </div>
                    <p className="text-gray-500 dark:text-gray-400">
                        {item.ingredients.join(", ")}
                    </p>
                </div>
                <div className="flex items-center justify-between mt-4">
                    <span className="text-lg font-bold text-red-500">€{item.price}</span>
                    <Button
                        className="bg-red-500 text-white hover:bg-red-600"
                        size="sm"
                        onClick={() => onClickButton(item)}
                    >
                        Add to Cart
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default SingleMenuCard;
