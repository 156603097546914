import React, { useState, useEffect, useCallback } from 'react';
import './Subscription.css';
import { Box, Button, Dialog, Typography, Paper, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import CardIcon from '@mui/icons-material/CreditCard';
import { getAuth } from "firebase/auth";
import Snackbar from "../../components/Snackbar/Snackbar";
import Sidebar from '../../components/SideBarAdmin/Sidebar';
import { getStorage, ref, getDownloadURL } from "firebase/storage";

const Subscription = () => {
    const [accountInfo, setAccountInfo] = useState(null);
    const [userId, setUserId] = useState(null);
    const [subscriptionData, setSubscriptionData] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const storage = getStorage();

    const [billingInfo, setBillingInfo] = useState({
        plan: 'Loading...',
        amountTotal: 'Loading...',
        activeSince: 'Loading...',
        benefits: [],
        nextInvoice: 'Loading...',
        nextInvoiceAmount: 'Loading...',
        paymentMethodDetails: {
            brand: 'No data',
            last4: '0000',
            cardholderName: 'Name on Card',
            exp_month: 'MM',
            exp_year: 'YYYY',
        },
        status: 'Loading...',
    });

    const [invoiceHistory, setInvoiceHistory] = useState([]);

    const [openCancelDialog, setOpenCancelDialog] = useState(false);
    const [openChangeCardDialog, setOpenChangeCardDialog] = useState(false);

    const handleOpenCancelDialog = () => setOpenCancelDialog(true);
    const handleCloseCancelDialog = () => setOpenCancelDialog(false);
    const handleOpenChangeCardDialog = () => setOpenChangeCardDialog(true);
    const handleCloseChangeCardDialog = () => setOpenChangeCardDialog(false);

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const handleDownloadInvoice = useCallback((invoiceId) => {
        const invoice = invoiceHistory.find((inv) => inv.id === invoiceId);

        setSnackbarMessage('Downloading invoice...');
        setOpenSnackbar(true);

        if (invoice && invoice.receiptUrl) {
            const link = document.createElement('a');
            link.href = invoice.receiptUrl;
            link.setAttribute('download', `Invoice_${invoiceId}.pdf`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            setSnackbarMessage('Invoice downloaded');
            setOpenSnackbar(true);
        } else {
            setSnackbarMessage('Invoice URL not found or invalid invoice ID.');
            setOpenSnackbar(true);
        }
    }, [invoiceHistory]);

    const fetchAccountInfo = useCallback(async (userId) => {
        try {
            const accountInfo = await fetch(`${process.env.REACT_APP_BACKEND_URL}/accountInfo/${userId}`);
            const accountData = await accountInfo.json();

            if (accountData.imageUrl) {
                const imageUrl = await getDownloadURL(ref(storage, accountData.imageUrl));
                accountData.imageUrl = imageUrl;
            }
            setAccountInfo(accountData);
        } catch (error) {
            console.error('Failed to fetch account info:', error);
        }
    }, [storage]);

    const fetchSubscriptionData = useCallback(async (sessionId) => {
        try {
            const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/retrieve-subscription-data`);
            url.searchParams.append('sessionId', sessionId);

            const response = await fetch(url, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });

            if (!response.ok) throw new Error('Network response was not ok');

            const data = await response.json();

            setBillingInfo({
                plan: data.planDetails?.id || 'N/A',
                activeSince: data.planDetails ? new Date(data.planDetails.createdAt * 1000).toLocaleDateString("en-US") : 'N/A',
                amountTotal: `${data.amountTotal} ${data.currency.toUpperCase()}` || 'N/A',
                benefits: data.benefits || [],
                status: data.status || 'N/A',
                nextInvoice: data.nextBillingDate || 'N/A',
                nextInvoiceAmount: data.amountTotal || 'N/A',
                paymentMethodDetails: {
                    brand: data.paymentMethodDetails?.brand || 'No data',
                    last4: data.paymentMethodDetails?.last4 || '0000',
                    cardholderName: data.paymentMethodDetails?.cardholderName || 'Name on Card',
                    exp_month: data.paymentMethodDetails?.exp_month || 'MM',
                    exp_year: data.paymentMethodDetails?.exp_year || 'YYYY',
                },
            });

            setInvoiceHistory(data.billingHistory || []);
        } catch (error) {
            console.error('Failed to fetch subscription data:', error);
        }
    }, []);

    useEffect(() => {
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
            setUserId(user.uid);
            Promise.all([fetchAccountInfo(user.uid), fetchSubscriptionData(user.uid)]);
        }
    }, [fetchAccountInfo, fetchSubscriptionData]);

    return (
        <div className="subscription-container">
            <Sidebar accountInfo={accountInfo} />
            <main className="billing-info">
                <Paper elevation={3} className="billing-card">
                    <div className="billing-header">
                        <Typography variant="h3" className="billing-title">Billing</Typography>
                        <div className="status-indicator">
                            {billingInfo.status === 'active' ? (
                                <>
                                    <FiberManualRecordIcon className="active-status" />
                                    <Typography component="span">Active</Typography>
                                </>
                            ) : (
                                <Typography component="span">Inactive</Typography>
                            )}
                        </div>
                    </div>

                    <div className="billing-details">
                        <div className="next-billing">
                            <Typography variant="subtitle1">Next Billing Date</Typography>
                            <Typography>{billingInfo.nextInvoice}</Typography>
                        </div>

                        <div className="billing-details-divider" />

                        <div className="payment-history-container">
                            <Typography variant="h6" className="payment-history-title">Payment History</Typography>
                            {invoiceHistory?.map((invoice, index) => (
                                <Paper elevation={2} className="invoice-card" key={index}>
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Box>
                                            <Typography className="invoice-date">{invoice.date}</Typography>
                                            <Typography className="invoice-amount">{invoice.amount} {invoice.currency}</Typography>
                                        </Box>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<ArrowDownward />}
                                            onClick={() => handleDownloadInvoice(invoice.id)}
                                            className="download-button"
                                            sx={{ backgroundColor: '#4caf50', '&:hover': { backgroundColor: '#388e3c' }, borderRadius: '20px', padding: '10px 20px', fontWeight: 'bold' }}
                                        >
                                            Download
                                        </Button>
                                    </Box>
                                </Paper>
                            ))}
                        </div>
                    </div>
                    <div className="payment-method">
                        <Typography variant="subtitle1">Payment Method</Typography>
                        <div className="payment-method-details">
                            <CardIcon />
                            <div>
                                <Typography>{billingInfo.paymentMethodDetails?.brand} ending in {billingInfo.paymentMethodDetails?.last4}</Typography>
                                <Typography>Name on card: {billingInfo.paymentMethodDetails?.cardholderName}</Typography>
                                <Typography>Expiry: {billingInfo.paymentMethodDetails?.exp_month}/{billingInfo.paymentMethodDetails?.exp_year}</Typography>
                            </div>
                        </div>
                        <Snackbar open={openSnackbar} message={snackbarMessage} onClose={handleSnackbarClose} />
                    </div>
                </Paper>
            </main>

            <Dialog open={openCancelDialog} onClose={handleCloseCancelDialog}>
                <DialogTitle>Cancel Subscription</DialogTitle>
                <DialogContent>
                    Are you sure you want to cancel your subscription?
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseCancelDialog} color="primary">
                        No
                    </Button>
                    <Button onClick={handleCloseCancelDialog} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openChangeCardDialog} onClose={handleCloseChangeCardDialog}>
                <DialogTitle>Change Payment Method</DialogTitle>
                <DialogContent>
                    Form to update payment method.
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseChangeCardDialog} color="primary">
                        Close
                    </Button>
                    <Button onClick={handleCloseChangeCardDialog} color="primary">
                        Save Changes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Subscription;
