import React from 'react';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import CheckCircle from '@mui/icons-material/CheckCircleRounded';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

function ItemSection({ itemSections, handleClick, queriedItemSection }) {
  return (
    <Card style={{ margin: '1rem', width: '96%', overflowX: 'auto' }}>
      <CardContent>
        <div style={{ width: 'max-content', maxWidth: '100%' }}>
          <Stack direction="row" spacing={1} flexWrap="nowrap">
            {itemSections.map((section, index) => (
              <Chip
                key={index}
                label={section.itemSection}
                style={{
                  marginRight: '5%',
                  marginBottom: '5%',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  backgroundColor: '#f83e3e',
                  color: '#FFFFFF',
                  borderColor: '#f83e3e',
                  width: '100%',
                }}
                clickable
                variant="outlined"
                onClick={() => handleClick(section.itemSection)}
                avatar={
                  queriedItemSection === section.itemSection ? (
                    <Tooltip title="Selected">
                      <IconButton>
                        <CheckCircle style={{ color: '#FFFFFF' }} />
                      </IconButton>
                    </Tooltip>
                  ) : null
                }
              />
            ))}
          </Stack>
        </div>
      </CardContent>
    </Card>
  );
}

export default ItemSection;
