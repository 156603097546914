import React, { useState, useEffect } from 'react';
import { initializeApp, getApps } from "firebase/app";
import { Navigate, useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";

export const AutherContext = React.createContext();

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [userRole, setUserRole] = useState(null);
    const [loading, setLoading] = useState(true);


    const firebaseConfig = {
        apiKey: process.env.REACT_APP_API_KEY,
        authDomain: process.env.REACT_APP_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_PROJECT_ID,
        storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_APP_ID,
        measurementId: process.env.REACT_APP_MEASUREMENT_ID,
    };

    if (!getApps().length) {
        initializeApp(firebaseConfig);
    }
    const auth = getAuth();

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setCurrentUser(user);
            setLoading(false);
        });

    }, []);

    return (
        <AutherContext.Provider value={{ currentUser, setCurrentUser, loading, userRole, setUserRole }}>
            {children}
        </AutherContext.Provider>
    );
};