import React from 'react';
import './TableView.css';
import { motion } from 'framer-motion'; // Ensure you have framer-motion installed

const tables = [
    { id: 'T1', status: 'available', chairs: 4, shape: 'circle' },
    { id: 'T2', status: 'reserved', chairs: 4, shape: 'circle' },
    { id: 'T3', status: 'served', chairs: 4, shape: 'circle' },
    // ... other tables
    { id: 'T6', status: 'served', chairs: 6, shape: 'capsule' },
    // ... other tables
];

const TableView = () => {
    return (
        <div className="table-view">
            {tables.map((table, index) => (
                <motion.div
                    key={table.id}
                    className={`table-container ${table.shape} ${table.status}`}
                    initial={{ opacity: 0, scale: 0 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ delay: index * 0.2 }}
                    whileHover={{ scale: 1.1 }}
                >
                    <div className="table">
                        <span className="table-core">{table.id}</span>
                        <div className={`chairs-container chairs-${table.chairs}`}>
                            {[...Array(table.chairs)].map((_, chairIndex) => (
                                <motion.span
                                    key={chairIndex}
                                    className="chair"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ delay: index * 0.2 + chairIndex * 0.1 }}
                                />
                            ))}
                        </div>
                    </div>
                </motion.div>
            ))}
        </div>
    );
};

export default TableView;
