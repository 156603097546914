import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { AutherContext } from "./index";

const PrivateRouteWrapper = ({ children, roleRequired, redirectTo }) => {
    const { currentUser, loading, userRole, setUserRole } = useContext(AutherContext);
    const [roleLoading, setRoleLoading] = useState(true);

    useEffect(() => {
        const fetchUserRole = async () => {
            if (currentUser) {
                const idToken = await currentUser.getIdToken();
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/get-user-role`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${idToken}`
                    },
                });
                const data = await response.json();
                setUserRole(data.role);
                setRoleLoading(false);
            }
        };
        fetchUserRole();
    }, [currentUser, setUserRole]);

    if (loading || roleLoading) {
        return <div>Loading...</div>;
    }

    if (currentUser && roleRequired && userRole !== roleRequired) {
        return <Navigate to={redirectTo} />;
    }

    return currentUser ? children : <Navigate to="/login" />;
};

export default PrivateRouteWrapper;
