import React, { useEffect, useState } from 'react';
import { useMediaQuery, Button, useTheme, Box, Typography, IconButton } from '@mui/material';
import { getAuth } from "firebase/auth";
import { initializeApp, getApps } from "firebase/app";
import TableCard from '../../models/Table';
import ReservationForm from '../../components/Form/Reservation';
import Sidebar from '../../components/SideBar/Sidebar';
import TimeAndDate from '../../components/DateAndTime/TimeAndDate';
import { useNavigate } from 'react-router-dom';
import { tokens } from '../../theme.js';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { Bell as BellIcon, RefreshCw as RefreshCwIcon, Table as TableIcon } from 'react-feather';
import 'tailwindcss/tailwind.css';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

if (!getApps().length) {
    initializeApp(firebaseConfig);
}
const storage = getStorage();

const DashboardWaiter = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const isTablet = useMediaQuery('(max-width: 1024px) and (min-width: 768px)');
    const isMobile = useMediaQuery('(max-width: 768px)');

    const [userId, setUserId] = useState(null);
    const [message, setMessage] = useState('');
    const [tables, setTables] = useState([]);
    const [reservations, setReservations] = useState([]);
    const [accountInfo, setAccountInfo] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    const [reservationFormOpen, setReservationFormOpen] = useState(false);
    const [reservationFormData, setReservationFormData] = useState({
        tableId: null,
        tableNumber: null,
    });

    function refreshReservations() {
        fetchReservations(userId, true);
    }

    const columns = [
        'Table ID',
        'Capacity',
        'Created At',
        'Modified At',
        'Restaurant ID',
        'Status',
        'Waiter ID',
    ];

    async function fetchTables(userId) {
        const storedToken = sessionStorage.getItem('firebaseIdToken');
        if (!storedToken) {
            setShowModal(true);
            return;
        }
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/tables/${userId}`, {
            headers: { 'Authorization': `Bearer ${storedToken}` }
        });
        const tablesData = await response.json();
        setTables(tablesData);
    }

    async function fetchAccountInfo(userId) {
        const accountInfo = await fetch(`${process.env.REACT_APP_BACKEND_URL}/accountInfo/${userId}`);
        const accountData = await accountInfo.json();
        if (accountData.imageUrl) {
            const imageUrl = await getDownloadURL(ref(storage, accountData.imageUrl));
            accountData.imageUrl = imageUrl;
        }
        setAccountInfo(accountData);
    }

    async function fetchReservations(userId, forceRefresh = false) {
        if (!forceRefresh) {
            const cachedData = localStorage.getItem(`reservations-${userId}`);
            const cacheTime = localStorage.getItem(`reservationsCacheTime-${userId}`);
            if (cachedData && cacheTime && Date.now() - parseInt(cacheTime) < 3600000) {
                setReservations(JSON.parse(cachedData));
                return;
            }
        }
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/reservations/${userId}`);
        const reservationsData = await response.json();
        setReservations(reservationsData);
        localStorage.setItem(`reservations-${userId}`, JSON.stringify(reservationsData));
        localStorage.setItem(`reservationsCacheTime-${userId}`, Date.now().toString());
    }

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                setUserId(user.uid);
                fetchTables(user.uid);
                fetchReservations(user.uid);
                fetchAccountInfo(user.uid);
            } else {
                setMessage("Please Login!");
            }
        });
        return () => {
            unsubscribe();
        };
    }, []);

    const placeOrderPage = () => {
        navigate('/orderPage', { replace: true });
    };

    const reserveTable = async (tableId, tableNumber) => {
        const table = tables.find((t) => t.id === tableId);
        const newStatus = table.status === "reserved" ? "available" : "reserved";
        if (newStatus === "reserved") {
            setReservationFormOpen(true);
            setReservationFormData({ tableId, tableNumber });
        } else {
            try {
                await fetch(`${process.env.REACT_APP_BACKEND_URL}/makeReservation`, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ userId, tableId, newState: newStatus }),
                });
                setTables(tables.map((t) => (t.id === tableId ? { ...t, status: newStatus } : t)));
            } catch (error) {
                console.error("Error:", error);
            }
        }
    };

    const redirectToLogin = () => {
        setShowModal(false);
        navigate('/login', { replace: true });
    };

    return (
        <Box className={`flex flex-col h-screen ${colors.background} ${colors.text}`}>
            <Box className="flex flex-row">
                <Sidebar accountInfo={accountInfo} />
                <Box className="flex flex-col flex-1">
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            mb: 2,
                            p: 2,
                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                            backgroundColor: colors.background,
                            color: colors.text,
                        }}
                    >
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: { xs: 'center', sm: 'flex-start' } }}>
                            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>DASHBOARD</Typography>
                            <Typography variant="subtitle1" sx={{ color: colors.blueAccent[700] }}>Welcome to your dashboard</Typography>
                        </Box>
                        <Box className="flex items-center gap-4">
                            <TimeAndDate />
                           
                        </Box>
                    </Box>

                    <Box className="flex flex-1 overflow-hidden p-4">
                        <Box className="flex flex-1 flex-col lg:flex-row">
                            <Box className="w-full lg:w-2/3 p-4 overflow-y-auto">
                                <TableCard columns={columns} data={tables} reservations={reservations} onButtonClick={reserveTable} />
                            </Box>
                            {!isMobile && (
                                <Box className="w-full lg:w-1/3 p-4 bg-gray-100 border-l overflow-y-auto">
                                    <ReservationForm userId={userId} reservationFormData={reservationFormData} tables={tables} />
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>

            {isMobile && (
                <Box className="p-4">
                    <ReservationForm userId={userId} reservationFormData={reservationFormData} tables={tables} />
                </Box>
            )}

            {showModal && (
                <Box className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <Box className="bg-white p-6 rounded shadow-lg">
                        <Typography className="text-xl font-bold mb-4">You are not authenticated</Typography>
                        <Button className="bg-red-500 text-white px-4 py-2 rounded" onClick={redirectToLogin}>Go to Login</Button>
                    </Box>
                </Box>
            )}

            <Box className="fixed bottom-0 w-full bg-gray-100 p-4 border-t flex justify-center gap-4">
                <Button
                    variant="contained"
                    color="primary"
                    style={{
                        backgroundColor: colors.blueAccent[700],
                        color: colors.grey[100],
                    }}
                    onClick={refreshReservations}
                >
                    <RefreshCwIcon className="mr-2" /> <span style={{ color: "#fff" }}>Refresh Reservations</span>
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    style={{
                        backgroundColor: colors.blueAccent[700],
                        color: colors.grey[100],
                    }}
                    onClick={placeOrderPage}
                >
                    <TableIcon className="mr-2" /> <span style={{ color: "#fff" }}>Place Order</span>
                </Button>
            </Box>
        </Box>
    );
};

export default DashboardWaiter;
