import React, { useState } from 'react';
import ConfigClass from "../../models/Consts/Consts.js"
import "./reservation.css"
import Snackbar from "../Snackbar/Snackbar.js"
import CustomTextField from "../TextField/TextField.js"


const ReservationForm = ({ userId, reservationFormData, tables }) => {
  const [reservations, setReservations] = useState([]);

  const [tableNumber, setTableNumber] = useState(reservationFormData.tableNumber);
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [amount, setAmount] = useState('');
  const [name, setName] = useState('');
  const [comment, setComment] = useState('');

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleMakeReservation = async (event) => {
    event.preventDefault();

    const tableId = reservationFormData.tableId;

    console.log("tableId: ", name, tableId, tableNumber, date, time, amount, comment);

    // Check if required fields are empty
    if (!name) {
      setSnackbarMessage('Please fill in the name field.');
      setOpenSnackbar(true);
      setTimeout(() => {
        setOpenSnackbar(false);
      }, 3000);
      return;
    }

    if (!date) {
      setSnackbarMessage('Please fill in the date field.');
      setOpenSnackbar(true);
      setTimeout(() => {
        setOpenSnackbar(false);
      }, 3000);
      return;
    }

    if (!time) {
      setSnackbarMessage('Please fill in the time field.');
      setOpenSnackbar(true);
      setTimeout(() => {
        setOpenSnackbar(false);
      }, 3000);
      return;
    }

    if (!amount) {
      setSnackbarMessage('Please fill in the amount field.');
      setOpenSnackbar(true);
      setTimeout(() => {
        setOpenSnackbar(false);
      }, 3000);
      return;
    }

    if (!reservationFormData.tableId) {
      setSnackbarMessage('Please select a table.');
      setOpenSnackbar(true);
      setTimeout(() => {
        setOpenSnackbar(false);
      }, 3000);
      return;
    }

    // Check if amount is between 1 and 20
    if (amount < 1 || amount > 20) {
      setSnackbarMessage('Amount must be between 1 and 20.');
      setOpenSnackbar(true);
      setTimeout(() => {
        setOpenSnackbar(false);
      }, 3000);
      return;
    }

    try {
      fetch(`${process.env.REACT_APP_BACKEND_URL}/reserveTable`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId, tableId, tableNumber, date, time, name, amount, comment
        }),
      });

      // Reset field values to empty
      setName('');
      setDate('');
      setTime('');
      setAmount('');
      setComment('');

      setSnackbarMessage("The reservation was made successfully");
      setOpenSnackbar(true);
      setTimeout(() => {
        setOpenSnackbar(false);
      }, 3000); // Close the snackbar after 3 seconds
    } catch (error) {
      console.error('Error:', error);
      setSnackbarMessage('An error occurred while making the reservation.');
      setOpenSnackbar(true);
      setTimeout(() => {
        setOpenSnackbar(false);
      }, 3000); // Close the snackbar after 3 seconds
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const incrementAmount = () => {
    setAmount(prevAmount => Math.min(20, prevAmount + 1));
  };

  const decrementAmount = () => {
    setAmount(prevAmount => Math.max(1, prevAmount - 1));
  };


  return (
    <div className="reservationContainer">
      <Snackbar open={openSnackbar} message={snackbarMessage} handleClose={handleSnackbarClose} />
      <form onSubmit={handleMakeReservation} className="form-container-reservation">
        <h2 className="title-reservation">Make Reservation</h2>

        <div className="table-number-container">
          {reservationFormData.tableNumber ? reservationFormData.tableNumber : 'Click on a table to book it'}
        </div>

        <CustomTextField
          margin="normal"
          required
          fullWidth 
          label="Name"
          name="name"
          autoComplete="name"
          autoFocus
          type="text"
          placeholder="Name"
          value={name}

          onChange={(e) => setName(e.target.value)}
        />

        <CustomTextField
          margin="normal"
          required
          fullWidth
          type="date"
          placeholder="Date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
        />

        <CustomTextField
          margin="normal"
          required
          fullWidth
          type="time"
          placeholder="Time"
          value={time}

          onChange={(e) => setTime(e.target.value)}
        />

        <div className="input-container">
          <button className="button-amount-decrease" onClick={decrementAmount}>-</button>
          <div className="amount-display-container">
            <span className="amount-label">Amount</span>
            <span className="amount-display">{amount}</span>
          </div>
          <button className="button-amount-increase" onClick={incrementAmount}>+</button>
        </div>

        <CustomTextField
          margin="normal"
          fullWidth
          type="text"
          placeholder="Comment"
          label="Comment"
          name="Comment"
          autoComplete="Comment"
          autoFocus
          value={comment}

          onChange={(e) => setComment(e.target.value)}
        />

        <button className="button-primary" type="submit">Reserve</button>
      </form>
    </div>
  );
};

export default ReservationForm;