import { ResponsiveLine } from '@nivo/line';
import { useTheme } from '@mui/material';
import { tokens } from '../../theme';

const LineChart = ({ data }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const CustomTooltip = ({ point }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
      <div
        style={{
          background: colors.background,
          padding: '12px',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0,0,0,0.3)',
          color: colors.text,
          fontSize: '14px',
        }}
      >
        <div><strong>Time:</strong> {point.data.xFormatted}</div>
        <div><strong>Orders:</strong> {point.data.yFormatted}</div>
      </div>
    );
  };

  return (
    <ResponsiveLine
      data={[{ id: 'orders', color: colors.blueAccent[700], data }]}
      margin={{ top: 50, right: 30, bottom: 50, left: 60 }}
      tooltip={CustomTooltip}
      xScale={{ type: 'point' }}
      yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
      curve="monotoneX" // Smooth the line
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: 'bottom',
        tickSize: 0,
        tickPadding: 10,
        tickRotation: 0,
        legend: 'Time',
        legendOffset: 36,
        legendPosition: 'middle',
        format: value => `${value}:00`,
        tickValues: ['0', '2','4', '6', '8', '10', '12', '14', '16', '18', '20', '22', '0']
      }}
      axisLeft={{
        orient: 'left',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Number of Orders',
        legendOffset: -40,
        legendPosition: 'middle',
        format: value => Math.round(value), // Ensure whole numbers
        tickValues: 5 // Adjust the number of ticks as needed
      }}
      enableGridX={false}
      enableGridY={true}
      gridYValues={5}
      pointSize={5}

      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      useMesh={true}
      enableSlices="x"

      theme={{
        tooltip: {
          container: {
            background: theme.palette.background.paper,
            color: theme.palette.text.primary,
            fontSize: '1rem',
            borderRadius: '4px',
            boxShadow: '0 3px 6px rgba(0,0,0,0.1)',
            padding: '5px',
          },
        },
        axis: {
          ticks: {
            text: {
              fill: colors.text,
              fontSize: 12,
            }
          },
          legend: {
            text: {
              fill: colors.text,
              fontSize: 16, // Larger font for legend
            }
          }
        },
        grid: {
          line: {
            stroke: colors.gridLine,
            strokeWidth: 1,
            strokeDasharray: '4 4',
          }
        }
      }}
       legends={[
        {
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: "left-to-right",
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
          effects: [
            {
              on: "hover",
              style: {
                itemBackground: "rgba(0, 0, 0, .03)",
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
};

export default LineChart;
