import '../../App.css';
import '../../Login.css';
import { getAuth } from "firebase/auth";
import { initializeApp, getApps } from "firebase/app";
import React, { useState, useEffect } from 'react';
import './NewRegister.css';
import Snackbar from '../../components/Snackbar/Snackbar';




function NewRegister() {

    const [file, setFile] = useState(null);
    const [userId, setUserId] = useState(null);
    const [message, setMessage] = useState('');

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');


    const [isKitchenForm, setIsKitchenForm] = useState(false);

    const [kitchenName, setKitchenName] = useState('');


    const [waiterName, setWaiterName] = useState('');
    const [phoneNumberWaiter, setPhoneNumberWaiter] = useState('');
    const [emailWaiter, setEmailWaiter] = useState('');
    const [passwordWaiter, setPasswordWaiter] = useState('');



    const firebaseConfig = {
        apiKey: process.env.REACT_APP_API_KEY,
        authDomain: process.env.REACT_APP_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_PROJECT_ID,
        storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_APP_ID,
        measurementId: process.env.REACT_APP_MEASUREMENT_ID,
    };

    if (!getApps().length) {
        initializeApp(firebaseConfig);
    }

    const auth = getAuth();
    useEffect(() => {


        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                // User is logged in. You can update your application's state with the user object.
                setUserId(user.uid);
            } else {
                // User is logged out. You can clear the user object or perform any other cleanup needed.
                console.log("User logged out!");
                setMessage("Please Login!");
            }
        });

        // Cleanup function that unsubscribes from the onAuthStateChanged listener
        return () => {
            unsubscribe();
        };
    }, []);

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };


    const registerWaiter = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('file', file);
        formData.append('waiterName', waiterName);
        formData.append('phoneNumberWaiter', phoneNumberWaiter);
        formData.append('emailWaiter', emailWaiter);
        formData.append('passwordWaiter', passwordWaiter);
        formData.append('adminId', userId);

        //optimize this code 
        if (waiterName === '' || phoneNumberWaiter === '' || emailWaiter === '' || passwordWaiter === '') {
            setSnackbarMessage('Please fill all the fields!');
            setOpenSnackbar(true);
            return;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(emailWaiter)) {
            setSnackbarMessage('Please enter a valid email!');
            setOpenSnackbar(true);
            return;
        }

        if (passwordWaiter.length < 6) {
            setSnackbarMessage('Please enter a password with at least 6 characters!');
            setOpenSnackbar(true);
            return;
        }

        if (phoneNumberWaiter.length < 10) {
            setSnackbarMessage('Please enter a valid phone number!');
            setOpenSnackbar(true);
            return;
        }

        if (file === null) {
            setSnackbarMessage('Please upload a profile picture!');
            setOpenSnackbar(true);
            return;
        }

        // Additional checker for uploaded image
        if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
            setSnackbarMessage('Please upload an image in JPEG or PNG format!');
            setOpenSnackbar(true);
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/registerWaiter`, {
                method: 'POST',
                body: formData,
            });

            // Handle the response from the server (e.g., show a success message or handle errors)
            if (response.ok) {
                setWaiterName('');
                setPhoneNumberWaiter('');
                setEmailWaiter('');
                setPasswordWaiter('');
                setFile(null);

                setSnackbarMessage('Waiter was successfully registered!');
                setOpenSnackbar(true);
            } else {
                console.error('Server error:', response.status);
                setSnackbarMessage('Something went wrong!, please try again!');
                setOpenSnackbar(true);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };


    // Add a new function to handle kitchen registration
    const registerKitchen = async (event) => {
        event.preventDefault();

        try {
            const formData = new FormData();
            formData.append('kitchenName', kitchenName);
            formData.append('restaurantId', userId);
            formData.append('adminId', userId);


            console.log('adminId', userId);

            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/RegisterKitchens`, {
                method: 'POST',
                body: formData,
            });

            // Handle the response from the server (e.g., show a success message or handle errors)
            if (response.ok) {
                setKitchenName('');
                setSnackbarMessage('Your order was successfully submitted!');
                setOpenSnackbar(true);
            } else {
                console.error('Server error:', response.status);
                setSnackbarMessage('Your order was successfully submitted!');
                setOpenSnackbar(true);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };


    return (
        <div className="new-register">
            <div className="toggle-buttons">
                <button
                    className={`toggle-button ${!isKitchenForm ? 'active' : ''}`}
                    onClick={() => setIsKitchenForm(false)}
                >
                    Waiter Form
                </button>
                <button
                    className={`toggle-button ${isKitchenForm ? 'active' : ''}`}
                    onClick={() => setIsKitchenForm(true)}
                >
                    Kitchen Form
                </button>
            </div>

            <Snackbar open={openSnackbar} message={snackbarMessage} onClose={handleSnackbarClose} />

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                {isKitchenForm ? (
                    <form onSubmit={registerKitchen} className="form">
                        <p>Register New Kitchen</p>
                        <input
                            type="name"
                            value={kitchenName}
                            onChange={(e) => setKitchenName(e.target.value)}
                            placeholder="Kitchen Name"
                        />
                        <button type="submit">Register</button>
                    </form>
                ) : (
                    <form onSubmit={registerWaiter} className="form">
                        <p>Register New Waiter</p>
                        <input
                            type="name"
                            value={waiterName}
                            onChange={(e) => setWaiterName(e.target.value)}
                            placeholder="Waiter Name"
                        />
                        <input
                            type="phone"
                            value={phoneNumberWaiter}
                            onChange={(e) => setPhoneNumberWaiter(e.target.value)}
                            placeholder="Phone Number"
                        />
                        <input
                            type="email"
                            value={emailWaiter}
                            onChange={(e) => setEmailWaiter(e.target.value)}
                            placeholder="Waiter Email"
                        />
                        <input
                            type="password"
                            value={passwordWaiter}
                            onChange={(e) => setPasswordWaiter(e.target.value)}
                            placeholder="Waiter Password"
                        />
                        <div className="file-input-container">
                            <label>Profile Picture</label>
                            <input type="file" onChange={handleFileChange} />
                            <div className="file-preview" data-text={file ? file.name : 'Choose file'}></div>
                        </div>
                        <button type="submit">Register</button>
                    </form>
                )}
            </div>
        </div>
    );
}

export default NewRegister;
