import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";

// color design tokens export
export const tokens = (mode) => ({
  ...(mode === "dark"
    ? {
        grey: {
          100: "#e0e0e0",
          200: "#c2c2c2",
          300: "#a3a3a3",
          400: "#858585",
          500: "#666666",
          600: "#525252",
          700: "#3d3d3d",
          800: "#292929",
          900: "#141414",
        },
        primary: {
          100: "#FF6B6B",
          200: "#C05151",
          300: "#B84E4E",
          400: "#B04B4B",
          500: "#A84848",
          600: "#A04444",
          700: "#903D3D",
          800: "#883A3A",
          900: "#803636",
        },
        greenAccent: {
          100: "#f7f8f3",
          200: "#eeeeee",
          300: "#e5e5e5",
          400: "#dcdcdc",
          500: "#d3d3d3",
          600: "#aaaaaa",
          700: "#818181",
          800: "#585858",
          900: "#2f2f2f",
        },
        redAccent: {
          100: "#F7444E",
          200: "#D03C44",
          300: "#A9333A",
          400: "#832A30",
          500: "#5C2126",
          600: "#46191D",
          700: "#311114",
          800: "#1B080A",
          900: "#060001",
        },
        blueAccent: {
          100: "#e0e8f6",
          200: "#b3c4e3",
          300: "#809fce",
          400: "#4d7ab9",
          500: "#1a569d",
          600: "#144584",
          700: "#0f346b",
          800: "#092552",
          900: "#041538",
        },
      }
    : {
        grey: {
          100: "#141414",
          200: "#292929",
          300: "#3d3d3d",
          400: "#525252",
          500: "#666666",
          600: "#858585",
          700: "#a3a3a3",
          800: "#c2c2c2",
          900: "#e0e0e0",
        },
        primary: {
          100: "#FF6B6B",
          200: "#FF7E7E",
          300: "#FF8787",
          400: "#FF9090",
          500: "#FFA3A3",
          600: "#FFB5B5",
          700: "#FFC8C8",
          800: "#FFDADA",
          900: "#FFEDED",
        },
        greenAccent: {
          100: "#f7f8f3",
          200: "#eeeeee",
          300: "#e5e5e5",
          400: "#dcdcdc",
          500: "#d3d3d3",
          600: "#aaaaaa",
          700: "#818181",
          800: "#585858",
          900: "#2f2f2f",
        },
        redAccent: {
          100: "#F7444E",
          200: "#FF7E7E",
          300: "#FF8787",
          400: "#FF9090",
          500: "#FFA3A3",
          600: "#FFB5B5",
          700: "#FFC8C8",
          800: "#FFDADA",
          900: "#FFEDED",
        },
        blueAccent: {
          100: "#e0e8f6",
          200: "#b3c4e3",
          300: "#809fce",
          400: "#4d7ab9",
          500: "#1a569d",
          600: "#144584",
          700: "#0f346b",
          800: "#092552",
          900: "#041538",
        },
      }),
});

// mui theme settings
export const themeSettings = (mode) => {
  const colors = tokens(mode);
  return {
    palette: {
      mode: mode,
      text: {
        primary: mode === 'dark' ? '#ffffff' : '#000000',
        secondary: mode === 'dark' ? '#b3b3b3' : '#4f4f4f',
      },
      ...(mode === "dark"
        ? {
            // palette values for dark mode
            primary: {
              main: colors.primary[500],
            },
            secondary: {
              main: colors.blueAccent[500],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: '#333', // light black color
            },
          }
        : {
            // palette values for light mode
            primary: {
              main: colors.primary[100],
            },
            secondary: {
              main: colors.blueAccent[500],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: "#fcfcfc",
            },
          }),
    },
    typography: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
  };
};

// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  // Get initial theme from localStorage or default to 'light'
  const initialTheme = localStorage.getItem('theme') || 'light';

  const [mode, setMode] = useState(initialTheme);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prev) => {
          const newMode = prev === "light" ? "dark" : "light";
          // Save the new theme in localStorage
          localStorage.setItem('theme', newMode);
          if (newMode === 'light') {
            document.documentElement.style.setProperty('--text-color', 'var(--text-color-light)');
          } else {
            document.documentElement.style.setProperty('--text-color', 'var(--text-color-dark)');
          }
          return newMode;
        });
      },
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return [theme, colorMode];
};