import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useStyles } from './style.js';

function Annimation({ loading, setLoading }) {
    const classes = useStyles();

    const handleClose = () => {
        setLoading(false);
    };

    return (
        <Backdrop className={classes.backdrop} open={loading} onClick={handleClose}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}

export default Annimation;
