import React, { useContext } from 'react';
import { TextField, useTheme } from '@mui/material';
import { ColorModeContext } from '../../theme';

export default function CustomTextField(props) {
    const theme = useTheme(); // Use the theme hook
    const colorMode = useContext(ColorModeContext);

    return (
        <TextField
            {...props}
            InputProps={{
                ...props.InputProps,
                inputProps: {
                    ...(props.InputProps?.inputProps || {}),
                    style: {
                        // Apply theme color directly
                        color: theme.palette.text.primary,
                        ...(props.InputProps?.inputProps?.style || {}),
                    },
                },
                style: {
                    ...(props.InputProps?.style || {}),
                },
            }}
            InputLabelProps={{
                ...props.InputLabelProps,
                style: {
                    // Apply theme color directly for the label as well
                    color: theme.palette.text.primary,
                    ...(props.InputLabelProps?.style || {}),
                },
            }}
        />
    );
}
