import React, { useContext } from 'react';
import { ColorModeContext } from '../../theme';

function Order({ orderData }) {
  // Safely access createdAt and updatedAt
  const createdAt = orderData.createdAt ? new Date(orderData.createdAt._seconds * 1000) : new Date();
  const updatedAt = orderData.updatedAt ? new Date(orderData.updatedAt._seconds * 1000) : new Date();
  const colorMode = useContext(ColorModeContext);

  return (
    <div className={`max-w-md mx-auto p-6 rounded-lg shadow-md mb-6 ${colorMode === 'dark' ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'}`}>
      <div className="p-4">
        <div className="flex justify-between items-center mb-4">
          <div>
            <h5 className="text-xl font-bold text-gray-900">Order #{orderData.id}</h5>
            <span className="text-sm text-gray-600 mt-1">{createdAt.toLocaleString()}</span>
          </div>
        </div>
        <div className="overflow-y-auto max-h-40">
          {orderData.items.map((item, index) => (
            <div key={index} className="mb-4">
              <h6 className="text-lg font-semibold text-gray-900">{item.itemName}</h6>
              <div className="text-sm text-gray-600">
                <p>Quantity: {item.quantity}</p>
                <p>Price: {item.price}€</p>
              </div>
              <div className="text-sm text-gray-600 mb-4">
                <p><strong>Ingredients:</strong> {item.ingredients.join(', ')}</p>
                {item.comment && (
                  <p><strong>Comments:</strong> {item.comment}</p>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="text-xs text-gray-500 mt-4">
          <p>Last updated: {updatedAt.toLocaleString()}</p>
        </div>
        <div className="flex justify-between items-center mt-4 border-t pt-4">
          <span className="text-lg font-semibold text-gray-900">Total:</span>
          <span className="text-lg font-semibold text-gray-900">{orderData.totalPrice}€</span>
        </div>
      </div>
    </div>
  );
}

export default Order;
