import React from 'react';

function MenuItemCard({ item }) {
  return (
    <div className="max-w-md mx-auto p-6 rounded-lg shadow-md mb-6 bg-white border-gray-200">
      <div className="p-4">
        <div className="mb-4">
          <img
            src={item.imageUrl}
            alt={item.itemName}
            style={{
              width: '100%',
              height: '200px', // Taller image
              objectFit: 'rectangle',
              borderRadius: '8px'
            }}
          />
        </div>
        <div>
          <h5 className="text-xl font-bold text-gray-900">{item.itemName}</h5>
          <p className="text-lg text-gray-600">{item.price}€</p>
        </div>
        <div className="mt-4 mb-4">
          <p className="text-sm text-gray-600"><strong>Ingredients:</strong> {item.ingredients.join(', ')}</p>
        </div>
        <p className="text-sm text-gray-600"><strong>Waiter:</strong> {item.waiterID}</p>
        <p className="text-sm text-gray-600 mt-1"><strong>Created At:</strong> {new Date(item.createdAt._seconds * 1000).toLocaleString()}</p>
        <p className="text-sm text-gray-600"><strong>Modified At:</strong> {new Date(item.modifiedAt._seconds * 1000).toLocaleString()}</p>
      </div>
    </div>
  );
}

export default MenuItemCard;
