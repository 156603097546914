import React, { useState, useContext } from 'react';
import { Typography, Divider, Box, Stack, Paper, ListItem, ListItemText, List, Card, CardContent, Select, MenuItem, FormControl } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import EventNoteIcon from '@mui/icons-material/EventNote';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CommentIcon from '@mui/icons-material/Comment';
import { ColorModeContext } from '../../theme.js';
import './styleOrder.css'; // Assuming CSS file is in the same directory

function Order({ orderData, userId }) {
  const createdAt = new Date(orderData.createdAt._seconds * 1000);
  const colorMode = useContext(ColorModeContext);
  const [orderStatus, setOrderStatus] = useState(orderData.status);

  const handleStatusChange = async (selectedStatus, itemId) => {
    console.log(`Updating status for item ${itemId} to ${selectedStatus}`);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/updateStatus/${itemId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          status: selectedStatus,
          id: itemId,
          userId: userId,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log(result);
      setOrderStatus(selectedStatus); // Update local state with new status
    } catch (error) {
      console.error("Failed to update status:", error);
    }
  };

  return (
    <Card className={`card ${colorMode}`}>
      <CardContent>
        <Typography variant="h4" gutterBottom className="order-heading">
          <ShoppingCartIcon className="icon" />Order: {orderData.id}
        </Typography>
        <Divider className="custom-divider" />
        <Box className="order-details">
          <Typography className="detail"><EventNoteIcon className="icon-small" />Order ID: {orderData.id}</Typography>
          <Typography className="detail"><RestaurantMenuIcon className="icon-small" />Table ID: {orderData.tableID}</Typography>
          <Typography className="status"><AccessTimeIcon className="icon-small" />Created At: {createdAt.toLocaleString()}</Typography>
          <Box className={`status-${orderStatus}`}>{orderStatus.toUpperCase()}</Box>
        </Box>
        <List>
          {orderData.items.map((item, index) => (
            <Paper key={index} className={`item ${colorMode}`}>
              <ListItem className="list-item">
                <ListItemText
                  primary={<Typography className="item-name">{item.itemName}</Typography>}
                  secondary={
                    <>
                      <Typography>Quantity: {item.quantity}, Price: {item.price}€</Typography>
                      <Typography>Ingredients: {item.ingredients.join(', ')}</Typography>
                      {item.comment && <Typography><CommentIcon className="icon-small" />{item.comment}</Typography>}
                      <FormControl className="form-control">
                        <Select
                          value={item.status || orderStatus}
                          onChange={(e) => handleStatusChange(e.target.value, orderData.id)}
                          className="status-select"
                        >
                          <MenuItem value="pending">Pending</MenuItem>
                          <MenuItem value="cooking">Cooking</MenuItem>
                          <MenuItem value="completed">Completed</MenuItem>
                        </Select>
                      </FormControl>
                    </>
                  }
                />
              </ListItem>
            </Paper>
          ))}
        </List>
        <Divider className="custom-divider" />
        <Stack direction="row" justifyContent="space-between" className="total-section">
          <Typography>Total:</Typography>
          <Typography className="total-price">{orderData.totalPrice}€</Typography>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default Order;
