import React, { useEffect, useState } from 'react';
import '../../App.css';
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { initializeApp, getApps } from "firebase/app";
import Snackbar from '../../components/Snackbar/Snackbar';


function App() {
    const firebaseConfig = {
        apiKey: process.env.REACT_APP_API_KEY,
        authDomain: process.env.REACT_APP_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_PROJECT_ID,
        storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_APP_ID,
        measurementId: process.env.REACT_APP_MEASUREMENT_ID,
    };

    // Initialize Firebase only if there are no apps
    if (!getApps().length) {
        initializeApp(firebaseConfig);
    }
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');


    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const navigate = useNavigate();
    useEffect(() => {
        const auth = getAuth();
        signOut(auth)
            .then(() => {
                console.log('User signed out!');
                navigate('/login');
                window.location.reload(); // Refresh the website
            })
            .catch((error) => {
                setSnackbarMessage('An error happened during sign out:', error);
                setOpenSnackbar(true);
            });
    }, []);

    return (
        <div>
            Loading...
            <Snackbar open={openSnackbar} message={snackbarMessage} onClose={handleSnackbarClose} />
        </div>
    );
}

export default App;