import React, { Suspense, lazy } from "react";
import { Link } from "react-router-dom";
import "../style.css";
import "../../../src/index.css"; // Ensure Tailwind CSS is imported
import Navbar from "../../components/Navbar/Navbar.js";
import Footer from "../../components/Footer.js";
import TrustShowcase from "./TrustShowcase.js"; // Import TrustShowcase
import { FaUtensils, FaRegCalendarAlt, FaBoxOpen, FaChartLine, FaUserFriends, FaFileAlt } from "react-icons/fa";

const ShowCase = lazy(() => import("./ShowCase.js"));
const SplitScreen = lazy(() => import("./SplitScreen.js"));

function Home() {
  return (
    <div>
      <Navbar />
      <div className="hero-container">
        <div className="hero-content text-left">
          <h1 className="hero-title">Revolutionieren Sie den Restaurantbetrieb mit OrderOps</h1>
          <p className="hero-subtitle">
            Unsere All-in-One-Restaurantmanagement-Plattform hilft Ihnen, Ihre Abläufe zu optimieren und Ihr Geschäft auszubauen.
          </p>
          <Link to="/signup" className="button-primary home">
            Loslegen
          </Link>
        </div>
      </div>
      <TrustShowcase /> {/* Use TrustShowcase */}
      <Suspense fallback={<div>Loading...</div>}>
        <ShowCase />
      </Suspense>
      <div className="features-section">
        <div className="container">
          <h2 className="section-title">Our Features</h2>
          <div className="feature-cards">
            <div className="feature-card">
              <FaUtensils size={50} className="feature-icon" />
              <h3 className="feature-card-title">Online Ordering System</h3>
              <p className="feature-card-description">
                Seamless online ordering with customizable menus and multiple payment options.
              </p>
            </div>
            <div className="feature-card">
              <FaRegCalendarAlt size={50} className="feature-icon" />
              <h3 className="feature-card-title">Table Reservation Management</h3>
              <p className="feature-card-description">
                Efficient table booking with real-time availability and instant confirmation.
              </p>
            </div>
            <div className="feature-card">
              <FaBoxOpen size={50} className="feature-icon" />
              <h3 className="feature-card-title">Admin Dashboard</h3>
              <p className="feature-card-description">
                Comprehensive insights and key metrics displayed prominently.
              </p>
            </div>
            <div className="feature-card">
              <FaChartLine size={50} className="feature-icon" />
              <h3 className="feature-card-title">Sales and Revenue Analytics</h3>
              <p className="feature-card-description">
                Detailed analytics with visualizations for better decision making.
              </p>
            </div>
            <div className="feature-card">
              <FaUserFriends size={50} className="feature-icon" />
              <h3 className="feature-card-title">Customer Relationship Management</h3>
              <p className="feature-card-description">
                Manage customer relationships with order history and preferences.
              </p>
            </div>
            <div className="feature-card">
              <FaFileAlt size={50} className="feature-icon" />
              <h3 className="feature-card-title">Real-Time Reporting</h3>
              <p className="feature-card-description">
                Generate and download real-time reports directly from the dashboard.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="business-value-section">
        <div className="container">
          <h2 className="section-title">Exploring the Comprehensive Set of <span className="highlight">Advanced Features</span></h2>
          <p className="section-subtitle">
            A Deep Dive into the Tools and Insights that Will Revolutionize Your Financial Management,
            Enabling Informed Decision-Making and Empowering Financial Success.
          </p>
          <div className="business-value-cards">
            <div className="business-value-card">
              <h3 className="business-value-title">Multi-Account Integration</h3>
              <p className="business-value-description">
                Unify your financial world in one place. Connect and manage multiple financial accounts,
                whether they are personal, business, or investment accounts.
              </p>
              <div className="card-visual">
                <img src="path/to/card-image.png" alt="My cards" />
                <p className="card-info">Add and update your card detail</p>
              </div>
            </div>
            <div className="business-value-card">
              <h3 className="business-value-title">Simplified Transaction Tracking</h3>
              <p className="business-value-description">
                Say goodbye to the hassle of manually categorizing expenses. Our platform automates expense tracking,
                helping you understand where your money is going.
              </p>
              <div className="transaction-visual">
                <img src="path/to/transaction-image.png" alt="Transaction History" />
              </div>
            </div>
            <div className="business-value-card">
              <h3 className="business-value-title">Streamlined Budgeting</h3>
              <p className="business-value-description">
                Achieve financial discipline with our budgeting tools. Set, track, and manage your budgets effortlessly,
                helping you stay on top of your expenses and savings targets.
              </p>
              <div className="budget-visual">
                <img src="path/to/budget-image.png" alt="Savings" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pricing-section">
        <div className="container">
          <h2 className="section-title">Customize Your Financial Destiny with <span className="highlight">Transparent Pricing Plans</span></h2>
          <p className="section-subtitle">Explore a Diverse Range of Pricing Options Tailored to Your Unique Goals and Achieve Unprecedented Control Over Your Prosperity</p>
          <div className="pricing-cards">
            <div className="pricing-card">
              <div className="pricing-card-header">
                <h3 className="pricing-title">Basic Plan</h3>
                <p className="pricing-description">Personal financial monitoring</p>
              </div>
              <div className="pricing-price">$9.99<span>/Month</span></div>
              <ul className="pricing-features">
                <li>Expense tracking and categorization</li>
                <li>Budget planning</li>
                <li>Customer Support</li>
              </ul>
              <Link to="/signup" className="button-primary">Get Started</Link>
            </div>
            <div className="pricing-card most-popular">
              <div className="pricing-card-header">
                <h3 className="pricing-title">Premium</h3>
                <p className="pricing-description">Comprehensive financial management</p>
              </div>
              <div className="pricing-price">$19.99<span>/Month</span></div>
              <ul className="pricing-features">
                <li>All features of the Basic Plan</li>
                <li>Credit monitoring and reporting</li>
                <li>In-depth investment analysis</li>
                <li>Customer Support</li>
              </ul>
              <Link to="/signup" className="button-primary">Get Started</Link>
              <div className="most-popular-badge">Most Popular</div>
            </div>
            <div className="pricing-card">
              <div className="pricing-card-header">
                <h3 className="pricing-title">Business Plan</h3>
                <p className="pricing-description">Financial solutions for your business</p>
              </div>
              <div className="pricing-price">$29.99<span>/Month</span></div>
              <ul className="pricing-features">
                <li>Expense tracking and categorization</li>
                <li>Financial reporting</li>
                <li>Customer Support</li>
              </ul>
              <Link to="/signup" className="button-primary">Get Started</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="cta-section">
        <div className="container">
          <h2 className="cta-title">Get started increasing your revenue</h2>
          <p className="cta-description">
            Join and integrate our app to increase the revenue and decrease your production cost.
          </p>
          <Link to="/signup" className="button-primary cta-button">Get Started</Link>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
