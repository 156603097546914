import React, { useState, useEffect, useContext } from 'react';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail, setPersistence, browserLocalPersistence } from "firebase/auth";
import { initializeApp, getApps } from "firebase/app";
import { AutherContext } from "../../PrivateRoute/index";
import { useNavigate } from 'react-router-dom';
import Snackbar from '../../components/Snackbar/Snackbar.js';
import Annimation from '../../components/LoadingAnnimation/Annimation.js';
import { Container, Box, Typography, Button, Grid, Checkbox, Link, ThemeProvider, FormControlLabel } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { themeSettings } from "../../theme.js";
import { ColorModeContext } from '../../theme';
import TextField from "../../components/TextField/TextField";

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [userId, setUserId] = useState(null);
    const { currentUser, userRole, setCurrentUser, setUserRole } = useContext(AutherContext);
    const [isKitchenStaff, setIsKitchenStaff] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const navigate = useNavigate();

    const { colorMode } = useContext(ColorModeContext);
    const theme = createTheme(themeSettings(colorMode));

    const handleResetPassword = async (email) => {
        try {
            await sendPasswordResetEmail(auth, email);
            setSnackbarMessage('Check your email for further instructions');
            setOpenSnackbar(true);
        } catch (error) {
            console.error('Error:', error);
            setSnackbarMessage('Error resetting password');
            setOpenSnackbar(true);
        }
    };

    const firebaseConfig = {
        apiKey: process.env.REACT_APP_API_KEY,
        authDomain: process.env.REACT_APP_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_PROJECT_ID,
        storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_APP_ID,
        measurementId: process.env.REACT_APP_MEASUREMENT_ID,
    };

    if (!getApps().length) {
        initializeApp(firebaseConfig);
    }

    const auth = getAuth();
    useEffect(() => {
        if (currentUser) {
            if (userRole === "waiter") {
                navigate('/reserve-table', { replace: true });
            } else if (userRole === "admin") {
                navigate('/dashboard', { replace: true });
            }
        }
    }, [currentUser, userRole, navigate]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            const auth = getAuth();
            console.log('Backend URL ENV:', process.env.REACT_APP_BACKEND_URL);

            // Enable local persistence
            await setPersistence(auth, browserLocalPersistence);
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            const idToken = await user.getIdToken();
            console.log('User logged in:', idToken);
            // Storing user token to the browser.
            sessionStorage.setItem('firebaseIdToken', idToken);

            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                },
                body: JSON.stringify({ idToken }),
            });
            const data = await response.json();
            console.log(idToken);
            setSnackbarMessage(data.message);
            setOpenSnackbar(true);

            console.log("User Role: ", data.role);
            setUserId(data.userId);
            // Get the user role from your backend
            setUserRole(data.role);
            setCurrentUser(user); // Add this line to update the currentUser state

            // Update navigation logic here
            if (user) {
                if (isKitchenStaff) {
                    navigate('/kitchenPage');
                } else if (data.role === "waiter" && data.role !== "admin") {
                    navigate('/reserve-table');
                } else if (data.role === "admin" && data.role !== "waiter") {
                    navigate('/dashboard');
                }
            } else if (!user) {
                navigate('/login');
            }
        } catch (error) {
            console.error('Error:', error);
            setSnackbarMessage('Login failed! ' + error.message);
            setOpenSnackbar(true);
        } finally {
            setLoading(false);
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    useEffect(() => {
        const auth = getAuth();
        auth.onAuthStateChanged((user) => {
            if (user) {
                user.getIdToken().then(async (idToken) => {
                    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/get-user-role`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${idToken}`
                        },
                    });
                    const data = await response.json();
                    setUserRole(data.role);
                    setCurrentUser(user);
                    if (data.role === "waiter") {
                        navigate('/reserve-table', { replace: true });
                    } else if (data.role === "admin") {
                        navigate('/dashboard', { replace: true });
                    }
                }).catch((error) => {
                    console.error('Error fetching user role:', error);
                });
            }
        });
    }, [navigate, setCurrentUser, setUserRole]);

    return (
        <ThemeProvider theme={theme}>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <Grid item xs={false} sm={4} md={7} sx={{
                    backgroundImage: 'url(https://source.unsplash.com/random)',
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: (t) => t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }} />
                <Grid item xs={12} sm={8} md={5} component={Box} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                    <Container component="main" maxWidth="xs">
                        <Box sx={{
                            my: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}>
                            <div className="flex items-center justify-center">
                                <Typography component="h1" variant="h1" className="ml-2" style={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
                                    OrderOps
                                </Typography>
                            </div>
                            <Typography component="p" variant="body2" className="mt-2 text-center text-gray-600 dark:text-gray-400">
                                Sign in to your account
                            </Typography>
                            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <Grid container alignItems="center" justifyContent="space-between">
                                    <Grid item>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isKitchenStaff}
                                                    onChange={(e) => setIsKitchenStaff(e.target.checked)}
                                                    name="kitchenStaff"
                                                    color="primary"
                                                />
                                            }
                                            label="Login as Kitchen Staff"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Link href="#" variant="body2" onClick={() => handleResetPassword(email)}>
                                            Forgot password?
                                        </Link>
                                    </Grid>
                                </Grid>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Sign In
                                </Button>
                                <Grid container justifyContent="center">
                                    <Grid item>
                                        <Link href="/signup" variant="body2">
                                            {"Don't have an account? Sign Up"}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Container>

                    <Annimation loading={loading} setLoading={setLoading} />
                    <Snackbar
                        open={openSnackbar}
                        autoHideDuration={3000}
                        onClose={handleSnackbarClose}
                        message={snackbarMessage}
                    />
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}

export default Login;
