import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <h3>OrderOps</h3>
                        <p>
                            Our mission is to help restaurant owners streamline their
                            operations and grow their businesses.
                        </p>
                    </div>
                    <div className="col">
                        <h3>Links</h3>
                        <ul>
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>
                                <Link to="/about">About</Link>
                            </li>
                            <li>
                                <Link to="/contact">Contact</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col">
                        <h3>Contact Us</h3>
                        <p>
                            Ostring 102
                            <br />
                            66424, Homburg
                            <br />
                            +49 175 3630429
                            <br />
                            info@myrestaurant.com
                        </p>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <p>&copy; 2024 OrderOps. All rights reserved.</p>
            </div>
        </footer>
    )
}

export default Footer;