import '../../App.css';
import React, { useState, useEffect } from 'react';
import OrderCard from '../../models/Order/Order.js';
import { getAuth } from "firebase/auth";
import { initializeApp, getApps } from "firebase/app";
import ConfigClass from "../../models/Consts/Consts.js"


function OrderPage() {


    const [userId, setUserId] = useState(null);

    const [orders, setOrders] = useState([]);
    const [queryValue, setQueryValue] = useState("");

    const firebaseConfig = {
        apiKey: process.env.REACT_APP_API_KEY,
        authDomain: process.env.REACT_APP_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_PROJECT_ID,
        storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_APP_ID,
        measurementId: process.env.REACT_APP_MEASUREMENT_ID,
    };
    if (!getApps().length) {
        initializeApp(firebaseConfig);
    }


    const handleChangeQueryValue = (e) => {
        setQueryValue(e.target.queryValue);
    };
    const handleSubmitQueryValue = (e) => {
        e.preventDefault();
        console.log("Form Submitted: " + queryValue);
    };

    async function fetchOrders(userId) {
        const cachedData = localStorage.getItem(`orders-${userId}`);
        const cacheTime = localStorage.getItem(`ordersCacheTime-${userId}`);
        if (cachedData && cacheTime && Date.now() - parseInt(cacheTime) < 3600000) {
            console.log('Loaded orders from cache');
            setOrders(JSON.parse(cachedData));
            return;
        }

        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/orderItems/${userId}`);
        const orderItems = await response.json();
        console.log("Fetched orders from Firebase:", orderItems);
        setOrders(orderItems);
 
        localStorage.setItem(`orders-${userId}`, JSON.stringify(orderItems));
        localStorage.setItem(`ordersCacheTime-${userId}`, Date.now().toString());
    }
    const auth = getAuth();
    useEffect(() => {


        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                // User is logged in. You can update your application's state with the user object.
                setUserId(user.uid);
                fetchOrders(user.uid);

            } else {
                // User is logged out. You can clear the user object or perform any other cleanup needed.
                console.log("User logged out!");

            }
        });

        // Cleanup function that unsubscribes from the onAuthStateChanged listener
        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <div>
            <h1 style={{ fontSize: '2rem', marginBottom: '20px' }}>Order Page</h1>

            <form onSubmit={handleSubmitQueryValue} style={{ display: 'flex', gap: '10px', alignItems: 'center', position: 'relative' }}>
                <input
                    type="text"
                    value={queryValue}
                    onChange={handleChangeQueryValue}
                    style={{
                        width: '100%',
                        maxWidth: '400px',
                        height: '40px',
                        padding: '10px 50px 10px 10px', // Add padding on the right for the button
                        fontSize: '1.2rem',
                        border: 'none',
                        borderRadius: '5px',
                        boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
                    }}
                    placeholder="Search orders"
                />
                <button
                    type="submit"
                    style={{
                        position: 'absolute', // Position the button absolutely
                        right: '10px', // Position it 10px from the right
                        backgroundColor: '#FF3D00',
                        color: 'white',
                        border: 'none',
                        padding: '10px 25px',
                        borderRadius: '7px',
                        fontSize: '1.2rem',
                        cursor: 'pointer',
                        transition: 'background-color 0.2s ease-out',
                    }}
                >
                    Search
                </button>
            </form>
            <div>
                {orders.sort((a, b) => {
                    const dateA = new Date(a.createdAt._seconds * 1000 + a.createdAt._nanoseconds / 1000000);
                    const dateB = new Date(b.createdAt._seconds * 1000 + b.createdAt._nanoseconds / 1000000);
                    return dateB - dateA;
                }).map((order) => (
                    <OrderCard key={order.createdAt._seconds} orderData={order} />
                ))}
            </div>
        </div>
    );
}
export default OrderPage;