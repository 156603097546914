import React from 'react';

const Badge = ({ className, children, variant }) => {
  return (
    <span className={`inline-block rounded-full text-sm ${className}`}>
      {children}
    </span>
  );
};

export default Badge;
