import React from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import Signup from './pages/Signup/Signup';
import Login from './pages/Login/Login';
import PrivateRouteWrapper from './PrivateRoute/PrivateRoute';
import { AuthProvider } from './PrivateRoute/index';

import DashboardAdmin from './pages/Admin/DashboardAdmin';
import ReserveTable from './pages/Waiter/DashboardWaiter';
import Logout from './pages/Logout/Logout';
import OrderViewPage from './pages/Waiter/OrderPage';
import OrderPage from './pages/SubmitOrders/OrderPage';
import NewWaiterPage from './pages/Admin/NewRegister';
import NewMenuItem from './pages/Admin/NewItems';
import KitchenPage from './pages/KitchenView/KitchenPage';
import About from './pages/About/About';
import Subscription from './pages/Subscription/Subscription';
import RegisterTableView from './pages/Admin/NewTable';

import { ColorModeContext, useMode } from './theme';
import { CssBaseline, ThemeProvider } from '@mui/material';

function App() {
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <div>

          <AuthProvider>
            <Routes>
              <Route index path="/" element={<Home />} />
              <Route path="Signup" element={<Signup />} />
              <Route path="Login" element={<Login />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/orderPage" element={<OrderPage />} />
              <Route path="/newMenuItem" element={<NewMenuItem />} />
              <Route path="/newWaiter" element={<NewWaiterPage />} />
              <Route path="/kitchenPage" element={<KitchenPage />} />
              <Route path="/subscription" element={<Subscription />} />
              <Route path="/about" element={<About />} />
              <Route path="/orderViewPage" element={<OrderViewPage />} />
              <Route path="/registerTableView" element={<RegisterTableView />} />

              <Route path="/dashboard" element={
                <PrivateRouteWrapper roleAllowed="admin" redirectTo="/reserve-table">
                  <DashboardAdmin />
                </PrivateRouteWrapper>
              } />


              <Route path="/reserve-table" element={
                <PrivateRouteWrapper roleAllowed="waiter" redirectTo="/dashboard">
                  <ReserveTable />
                </PrivateRouteWrapper>
              } />
            </Routes>
          </AuthProvider>
        </div>
      </ThemeProvider>

    </ColorModeContext.Provider>
  );
}

export default App;
