import React from 'react';
import { FaFacebook, FaSpotify, FaAirbnb, FaAmazon, FaSlack, FaBox, FaWarehouse } from 'react-icons/fa';
import './TrustShowcase.css';

const TrustShowcase = () => {
  const trustedParties = [
    { name: 'Facebook', icon: <FaFacebook size={50} color="black" /> },
    { name: 'Upwork', icon: <FaBox size={50} color="black" /> }, // Changed to FaBox as a placeholder for Upwork
    { name: 'Spotify', icon: <FaSpotify size={50} color="black" /> },
    { name: 'Airbnb', icon: <FaAirbnb size={50} color="black" /> },
    { name: 'Amazon', icon: <FaAmazon size={50} color="black" /> },
    { name: 'Slack', icon: <FaSlack size={50} color="black" /> },
    { name: 'Walmart', icon: <FaWarehouse size={50} color="black" /> }, // Changed to FaWarehouse as a placeholder for Walmart
  ];

  return (
    <div className="trust-showcase">
      <h3 className="trust-title">Trusted by</h3>
      <div className="trust-logos">
        {trustedParties.map((party, index) => (
          <div key={index} className="trust-logo">
            {party.icon}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TrustShowcase;
