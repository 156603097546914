import '../../App.css';
import '../../Login.css';
import './NewItem.css';
import { getAuth } from "firebase/auth";
import { initializeApp, getApps } from "firebase/app";
import React, { useState, useEffect } from 'react';
import Snackbar from '../../components/Snackbar/Snackbar';
import MuiAlert from '@mui/material/Alert';



function NewItems() {

    const [file, setFile] = useState(null);
    const [userId, setUserId] = useState(null);
    const [message, setMessage] = useState('');

    const [price, setPrice] = useState('');
    const [itemsSection, setItemSection] = useState('');
    const [itemName, setItemName] = useState('');
    const [ingredients, setIngredients] = useState([]);
    const [newIngredient, setNewIngredient] = useState('');

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');



    if (!getApps().length) {
        initializeApp(firebaseConfig);
    }


    const handleIngredientChange = (index, value) => {
        const updatedIngredients = [...ingredients];
        updatedIngredients[index] = value;
        setIngredients(updatedIngredients);
    };

    const handleAddIngredient = () => {
        const updatedIngredients = [...ingredients, newIngredient];
        setIngredients(updatedIngredients);
        setNewIngredient('');
    };

    const handleRemoveIngredient = (index) => {
        const updatedIngredients = [...ingredients];
        updatedIngredients.splice(index, 1);
        setIngredients(updatedIngredients);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };



    const firebaseConfig = {
        apiKey: process.env.REACT_APP_API_KEY,
        authDomain: process.env.REACT_APP_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_PROJECT_ID,
        storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_APP_ID,
        measurementId: process.env.REACT_APP_MEASUREMENT_ID,
    };

    if (!getApps().length) {
        initializeApp(firebaseConfig);
    }

    const auth = getAuth();
    useEffect(() => {


        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                // User is logged in. You can update your application's state with the user object.
                setUserId(user.uid);

            } else {
                // User is logged out. You can clear the user object or perform any other cleanup needed.
                console.log("User logged out!");
                setMessage("Please Login!");
            }
        });

        // Cleanup function that unsubscribes from the onAuthStateChanged listener
        return () => {
            unsubscribe();
        };
    }, []);


    const addItemToMenu = async (event) => {
        event.preventDefault();
     
        if (!itemName || !itemsSection || !price || ingredients.length === 0 || !file) {
            setSnackbarMessage('Please fill all the fields and add at least one ingredient!');
            setOpenSnackbar(true);
            return;
        }
     
        try {
            // Prepare the form data
            const formData = new FormData();
            formData.append('price', price);
            formData.append('itemName', itemName);
            ingredients.forEach((ingredient, index) => {
                formData.append(`ingredients[${index}]`, ingredient);
            });
            formData.append('file', file);
            formData.append('itemsSection', itemsSection);
            formData.append('userId', userId);
     
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/addMenu`, {
                method: 'POST',
                body: formData,
            });
     
            if (response.ok) {
                // Resetting the form fields after successful submission
                setPrice('');
                setItemSection('');
                setItemName('');
                setIngredients([]);
                setFile(null);
     
                setSnackbarMessage("Item added to the menu successfully");
            } else {
                setSnackbarMessage('Failed to add item to menu. Please try again.');
            }
        } catch (error) {
            setSnackbarMessage('An error occurred while adding item to menu. Please try again.');
        } finally {
            setOpenSnackbar(true);
        }
     };
     

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };


    return (
        <div className="newRegister">

            <Snackbar open={openSnackbar} message={snackbarMessage} onClose={handleSnackbarClose} />

            <form onSubmit={addItemToMenu} className="form">
                <p>Add New Items to the Menu</p>
                <input
                    type="name"
                    value={itemName}
                    onChange={(e) => setItemName(e.target.value)}
                    className="formInput"
                    placeholder="Item Name"
                />

                <input
                    type="name"
                    value={itemsSection}
                    onChange={(e) => setItemSection(e.target.value)}
                    className="formInput"
                    placeholder="Item Section"
                />

                <input
                    type="price"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    className="formInput"
                    placeholder="Item Price"
                />


                <div className="ingredientsSection">
                    <label htmlFor="newIngredient">Ingredients</label>
                    <div className="ingredientInputWrapper">
                        <input
                            type="text"
                            id="newIngredient"
                            value={newIngredient}
                            onChange={(e) => setNewIngredient(e.target.value)}
                            className="formInput"
                            placeholder="Add New Ingredient"
                        />
                        <button type="button" onClick={handleAddIngredient} className="addIngredientButton">
                            Add
                        </button>
                    </div>
                    <ul className="ingredientList">
                        {ingredients.map((ingredient, index) => (
                            <li key={index} className="ingredientItem">
                                {ingredient}
                                <span onClick={() => handleRemoveIngredient(index)} className="removeIngredientIcon">
                                    x
                                </span>
                            </li>
                        ))}
                    </ul>
                </div>


                <div className="fileInputContainer">
                    <label>Profile Picture</label>
                    <input type="file" onChange={handleFileChange} className="fileInput" />
                    <div className="filePreview">
                        {file ? file.name : 'Choose file'}
                    </div>
                </div>
                <button type="submit" className="submitButton">
                    Register
                </button>
            </form>
        </div>
    );
}


export default NewItems;
