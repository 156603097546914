import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import './style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import icon from "../components/assets/icon.svg"

function Table({ columns, data, reservations, onButtonClick }) {
    const getReservationsForTable = (tableId) => {
        const reservationsForTable = reservations.filter((reservation) => {
            return reservation.tableId === tableId;
        });
        return reservationsForTable;
    };

    const getGridArea = (index) => {
        const gridAreas = [
            '1 / 1 / 2 / 2', // Table 1
            '2 / 1 / 3 / 2', // Table 2
            '3 / 1 / 4 / 2', // Table 3
            '1 / 2 / 2 / 3', // Table 4
            '2 / 2 / 3 / 3', // Table 5
            '3 / 2 / 4 / 3', // Table 6
            '2 / 3 / 3 / 4', // Table 7
        ];
        return gridAreas[index];
    };

    const isTableBooked = (tableId) => {
        const reservationsForTable = getReservationsForTable(tableId);
        const now = new Date();
        const nextHour = new Date(now.getTime() + 60 * 60 * 1000); // Add one hour to current time
        return reservationsForTable.some((reservation) => {
            const reservationDate = new Date(reservation.date + 'T' + reservation.time); // Combine date and time strings
            return reservationDate > now && reservationDate < nextHour;
        });
    };

    const [isHovered, setIsHovered] = useState(false);

    return (
        <div>
            <div className="tables-container" style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}>
                {data.map((row, rowIndex) => (
                    <Card
                        key={rowIndex}
                        onClick={() => onButtonClick(row.id, row.tableId)}
                        onMouseEnter={() => setIsHovered(rowIndex)}

                        onMouseLeave={() => setIsHovered(null)}
                        style={{
                            cursor: "pointer",
                            marginBottom: '1rem',
                            marginLeft: '1rem',
                            width: '250px', // Smaller fixed width
                            height: '150px', // Smaller fixed height
                            gridArea: getGridArea(rowIndex),
                            boxShadow: '5px 10px 18px #888888', // 3D effect in normal state
                            backgroundColor: isHovered === rowIndex ? '#fcb7b7' : '' // Change background color on hover
                        }}
                        className={isTableBooked(row.id) ? 'tableRowBooked' : 'tableRowAvailable'}
                    >


                        <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div>
                                    <Card.Title style={{ fontFamily: 'Arial', fontSize: '24px' }}>
                                        <span style={{
                                            color: "#FFF",
                                            textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                            fontFamily: "Overlock",
                                            fontSize: "2.25rem",
                                            fontStyle: "normal",
                                            fontWeight: 400,
                                            lineHeight: "normal",
                                        }}>
                                            {row.tableId}
                                        </span>
                                    </Card.Title>
                                </div>
                                <div>
                                    <Card.Subtitle>
                                        <span style={{
                                            color: "#FFF",
                                            textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                            fontFamily: "Overlock",
                                            fontSize: "2.25rem",
                                            fontStyle: "normal",
                                            fontWeight: 300,
                                            lineHeight: "normal",
                                        }}>
                                            {row.capacity}
                                        </span>
                                        <img src={icon} style={{
                                            width: "2.5rem",
                                            height: "2.5rem",
                                        }} />
                                    </Card.Subtitle>
                                </div>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {getReservationsForTable(row.id).map((res, resIndex) => (
                                    <div key={resIndex} style={{ marginBottom: '0.5rem' }}>
                                        <span>{res.name}</span>
                                        <span style={{ marginLeft: '1rem' }}>{res.date}</span>
                                        <span style={{ marginLeft: '1rem' }}>{res.time}</span>
                                    </div>
                                ))}
                            </div>

                            {isHovered === rowIndex && (
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <button className='button-secondary' onClick={() => onButtonClick(row.id, row.tableId)}>Book Table</button>
                                    <button className='button-secondary' onClick={() => onButtonClick(row.id, row.tableId)}>Place Order</button>
                                </div>
                            )}

                        </Card.Body>
                    </Card>
                ))}
            </div>
        </div>
    );
}
export default Table;