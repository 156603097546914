import React from 'react';
import { NavLink } from 'react-router-dom';
import './style.css';
import { SidebarData } from './sideBarData';
import { Box, IconButton, useTheme } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";

function Sidebar() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  const handleNavLinkClick = (event, item) => {
    if (item.id === 'theme-button') {
      event.preventDefault();
      colorMode.toggleColorMode();
    }
  };;

  return (
    <div className='sidebar'>
      <ul className='sidebar-list'>
        {SidebarData.map((item, index) => {
          return (
            <li key={index} className={item.cName}>
              <NavLink to={item.path} activeClassName='active' onClick={(event) => handleNavLinkClick(event, item)}>
                {item.icon}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default Sidebar;
