import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Drawer, IconButton, useMediaQuery, useTheme, List, ListItem, ListItemText } from '@mui/material';
import './Navbar.css';

function Navbar() {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const location = useLocation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    return (
        <nav className="nav-content">
            <h1 className="logo"><Link to="/" style={{ color: 'inherit', textDecoration: 'inherit' }}>OrderOps</Link></h1>
            {isMobile ? (
                <>
                    <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle} className="menu-icon">
                        <MenuIcon />
                    </IconButton>
                    <Drawer
                        anchor="right"
                        open={drawerOpen}
                        onClose={handleDrawerToggle}
                        PaperProps={{
                            style: {
                                backgroundColor: theme.palette.background.paper,
                                padding: '20px',
                                width: '200px',
                            },
                        }}
                    >
                        <div className="drawer-header">
                            <IconButton onClick={handleDrawerToggle}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <List onClick={handleDrawerToggle} className="nav-links">
                            <ListItem button component={Link} to="/" selected={location.pathname === "/"}>
                                <ListItemText primary="Home" />
                            </ListItem>
                            <ListItem button component={Link} to="/about" selected={location.pathname === "/about"}>
                                <ListItemText primary="About" />
                            </ListItem>
                            <ListItem button component={Link} to="/contact" selected={location.pathname === "/contact"}>
                                <ListItemText primary="Contact" />
                            </ListItem>
                            <ListItem button component={Link} to="/login" selected={location.pathname === "/login"}>
                                <ListItemText primary="Login" />
                            </ListItem>
                        </List>
                    </Drawer>
                </>
            ) : (
                <div className="flex items-center gap-5">
                    <ul className="nav-links">
                        <li className={location.pathname === "/" ? "active" : ""}><Link to="/">Home</Link></li>
                        <li className={location.pathname === "/about" ? "active" : ""}><Link to="/about">About</Link></li>
                        <li className={location.pathname === "/contact" ? "active" : ""}><Link to="/contact">Contact</Link></li>
                    </ul>
                    <Link to="/login" className={`nav-button ${location.pathname === "/login" ? "active" : ""}`}>
                        Login
                    </Link>
                </div>
            )}
        </nav>
    );
}

export default Navbar;
