import React, { useState, useEffect } from 'react';
import { getAuth } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Button, IconButton, Drawer, useMediaQuery, Box, Typography, Badge } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import FilterListIcon from '@mui/icons-material/FilterList';
import SortIcon from '@mui/icons-material/Sort';
import { initializeApp, getApps } from "firebase/app";
import SingleMenuCard from '../../models/MenuItemsOrderVersion.js'; // Import SingleMenuCard
import Cart from '../../components/Cart/Cart.js';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import Snackbar from '../../components/Snackbar/Snackbar';
import { tokens } from '../../theme.js';
import 'tailwindcss/tailwind.css';
import '../../App.css';
import './submit_order.css';  // Create a new CSS file for animation styles

const firebaseConfig = {
    // Your Firebase config
};

if (!getApps().length) {
    initializeApp(firebaseConfig);
}
const storage = getStorage();

function OrderPage() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();
    const [userId, setUserId] = useState(null);
    const [message, setMessage] = useState('');
    const [selectedItemOrderDate, setSelectedItemOrderDate] = useState([]);
    const [status, setStatus] = useState('');
    const [orders, setOrders] = useState([]);
    const [tables, setTables] = useState([]);
    const [reservations, setReservations] = useState([]);
    const [menuItems, setMenuItems] = useState([]);
    const [menuSections, setMenuSection] = useState([]);
    const [queriedItemSection, setQueriedItemSection] = useState('');
    const [itemData, setItemData] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [isCartOpen, setIsCartOpen] = useState(false);
    const isSmallDevice = useMediaQuery('(max-width: 768px)');

    async function fetchMenuItems(userId, queriedItemSection) {
        const cacheLifetime = 86400000; // 24 hours in milliseconds
        const cacheKey = `menuItems-${userId}-${queriedItemSection}`;
        const now = new Date().getTime();

        const cachedData = localStorage.getItem(cacheKey);
        if (cachedData) {
            const { data, timestamp } = JSON.parse(cachedData);
            if (now - timestamp < cacheLifetime) {
                setMenuItems(data);
                return;
            }
        }

        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/menuItems/${userId}/${queriedItemSection}`);
        const menuItemsData = await response.json();

        const menuItemsWithImages = await Promise.all(
            menuItemsData.map(async (menuItem) => {
                try {
                    const imageUrl = await getDownloadURL(ref(storage, menuItem.imageUrl));
                    menuItem.imageUrl = imageUrl;
                } catch (error) {
                    console.error('Error getting download URL:', error);
                    menuItem.imageUrl = null;
                }
                return menuItem;
            })
        );

        setMenuItems(menuItemsWithImages);
        localStorage.setItem(cacheKey, JSON.stringify({ data: menuItemsWithImages, timestamp: now }));
    }

    async function fetchMenuSections(userId) {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/itemSections/${userId}`);
        const menuSections = await response.json();
        setMenuSection(menuSections);
    }

    async function fetchReservations(userId) {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/reservations/${userId}`);
        const reservationsData = await response.json();
        setReservations(reservationsData);
    }

    const auth = getAuth();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                setUserId(user.uid);
                fetchReservations(user.uid);
                fetchMenuItems(user.uid, queriedItemSection);
                fetchMenuSections(user.uid);
            } else {
                setMessage("Please Login!");
            }
        });
        return () => {
            unsubscribe();
        };
    }, [queriedItemSection]);

    useEffect(() => {
        if (userId) {
            fetchMenuItems(userId, queriedItemSection);
        }
    }, [userId, queriedItemSection]);

    const handleClick11 = (id) => {
        setQueriedItemSection(id);
    };

    const addItemToCart = (item) => {
        setSelectedItemOrderDate(prevState => [...prevState, item]);
        setSnackbarMessage('Item added to cart!');
        setOpenSnackbar(true);
    };

    const removeItem = index => {
        setSelectedItemOrderDate(prevItems => prevItems.filter((item, i) => i !== index));
    };

    const removeIngredient = (itemIndex, ingredientIndex) => {
        setSelectedItemOrderDate(prevItems => {
            const updatedItems = [...prevItems];
            const item = { ...updatedItems[itemIndex] };
            item.ingredients.splice(ingredientIndex, 1);
            updatedItems[itemIndex] = item;
            return updatedItems;
        });
    };

    const increaseQuantity = index => {
        setSelectedItemOrderDate(prevItems => prevItems.map((item, i) => i === index ? { ...item, quantity: item.quantity + 1 } : item));
    };

    const decreaseQuantity = index => {
        setSelectedItemOrderDate(prevItems => prevItems.map((item, i) => i === index && item.quantity > 1 ? { ...item, quantity: item.quantity - 1 } : item));
    };

    const submitOrder = (data, totalPrice) => {
        const numericTotalPrice = +totalPrice;
        const formattedTotalPrice = parseFloat(numericTotalPrice.toFixed(2));

        setStatus('pending')
        try {
            fetch(`${process.env.REACT_APP_BACKEND_URL}/orders`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId, data, totalPrice: formattedTotalPrice }),
            });
        } catch (error) {
            console.error('Error:', error);
        }
        setSnackbarMessage('Your order was successfully submitted!');
        setOpenSnackbar(true);
        setSelectedItemOrderDate([]);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const toggleCart = () => {
        setIsCartOpen(!isCartOpen);
    };

    return (
        <Box className="order-page flex flex-col min-h-screen" sx={{ backgroundColor: colors.background, color: colors.text }}>
            <Box className="order-header p-4 flex justify-between items-center" sx={{ backgroundColor: colors.background, color: colors.blueAccent[700], boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' }}>
                <Box className="flex items-center gap-2">
                    <IconButton onClick={() => navigate(-1)} sx={{ color: colors.blueAccent[700] }}>
                        <ArrowLeftIcon className="w-5 h-5" />
                    </IconButton>
                    <Typography variant="h4" className="font-bold">Restaurant Menu</Typography>
                </Box>
                {isSmallDevice && (
                    <IconButton onClick={toggleCart} sx={{ color: colors.blueAccent[700] }}>
                        <Badge badgeContent={selectedItemOrderDate.length} color="error">
                            <ShoppingCartIcon />
                        </Badge>
                    </IconButton>
                )}
            </Box>

            <Box className="flex-1 flex overflow-hidden">
                <Box className="flex flex-col w-full lg:w-3/4 p-4 overflow-y-auto">
                    <Box className="order-nav-container flex justify-between items-center p-4 shadow mb-4 flex-wrap" sx={{ backgroundColor: colors.background }}>
                        <Box className="flex items-center gap-2 overflow-x-auto flex-wrap">
                            {menuSections.map((section, index) => (
                                <Button
                                    key={index}
                                    className={`order-nav-item ${queriedItemSection === section.itemSection ? 'bg-red-500 text-white' : 'bg-white text-red-500 border-red-500'} rounded-full`}
                                    onClick={() => handleClick11(section.itemSection)}
                                    size="small"
                                    variant="outlined"
                                    sx={{
                                        '&.bg-red-500': {
                                            backgroundColor: colors.blueAccent[700],
                                            color: colors.background,
                                            fontcolor: colors.background,
                                            '&:hover': { backgroundColor: colors.blueAccent[700] }
                                        },
                                        maxWidth: '100px', // Set a max width to prevent stretching
                                        margin: '4px' // Add margin for spacing
                                    }}
                                >
                                    {section.itemSection}
                                </Button>
                            ))}
                        </Box>
                        <Box className="flex items-center gap-2">
                            {isSmallDevice ? (
                                <>
                                    <IconButton sx={{ color: colors.blueAccent[700], backgroundColor: colors.background, '&:hover': { backgroundColor: colors.blueAccent[700] } }} size="small">
                                        <FilterListIcon />
                                    </IconButton>
                                    <IconButton sx={{ color: colors.blueAccent[700], backgroundColor: colors.background, '&:hover': { backgroundColor: colors.blueAccent[700] } }} size="small">
                                        <SortIcon />
                                    </IconButton>
                                </>
                            ) : (
                                <>
                                    <Button sx={{ color: colors.blueAccent[700],  backgroundColor: colors.background, '&:hover': { backgroundColor: colors.blueAccent[700] } }} size="small" variant="outlined">
                                        <FilterListIcon className="w-5 h-5 mr-2" />
                                        Filter
                                    </Button>
                                    <Button sx={{ color: colors.blueAccent[700],  backgroundColor: colors.background, '&:hover': { backgroundColor: colors.blueAccent[700] } }} size="small" variant="outlined">
                                        <SortIcon className="w-5 h-5 mr-2" />
                                        Sort
                                    </Button>
                                </>
                            )}
                        </Box>
                    </Box>

                    <Box className="menu-section-container flex justify-center">
                        <Box className="menu-section w-full">
                            <div className="menu-container grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
                                {menuItems.filter(item => item.itemSection === queriedItemSection).map(menuItem => (
                                    <SingleMenuCard key={menuItem.itemID} item={menuItem} onClickButton={addItemToCart} />
                                ))}
                            </div>
                        </Box>
                    </Box>
                </Box>

                <Drawer
                    anchor="right"
                    open={isSmallDevice && isCartOpen}
                    onClose={toggleCart}
                    className="lg:hidden"
                >
                    <Cart
                        item={selectedItemOrderDate}
                        removeItem={removeItem}
                        submitOrder={submitOrder}
                        increaseQuantity={increaseQuantity}
                        decreaseQuantity={decreaseQuantity}
                        removeIngredient={removeIngredient}
                    />
                </Drawer>

                {!isSmallDevice && (
                    <Box className="cart-section shadow p-4 w-full lg:w-96 h-full overflow-y-auto fixed right-0 top-0" sx={{ backgroundColor: colors.background, color: colors.text }}>
                        <Cart
                            item={selectedItemOrderDate}
                            removeItem={removeItem}
                            submitOrder={submitOrder}
                            increaseQuantity={increaseQuantity}
                            decreaseQuantity={decreaseQuantity}
                            removeIngredient={removeIngredient}
                        />
                    </Box>
                )}
            </Box>

            <Snackbar open={openSnackbar} message={snackbarMessage} onClose={handleSnackbarClose} />
        </Box>
    );
}

function ArrowLeftIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="m12 19-7-7 7-7" />
            <path d="M19 12H5" />
        </svg>
    )
}

export default OrderPage;
