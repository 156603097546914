import React from 'react';
import TableView from "../../components/Tables/tables";
import Sidebar from '../../components/SideBar/Sidebar.js';
import './About.css'; // Ensure you have an About.css file for custom styles

const About = () => {
    return (
        <div className="about-container">
            <Sidebar />
            <div className="table-view-container">
                <TableView />
            </div>
        </div>
    );
};

export default About;
