import './kitchenPage.css';
import { getAuth } from "firebase/auth";
import { initializeApp, getApps } from "firebase/app";
import OrderCard from '../../models/OrderKitchenVersion/OrderKitchenVersion.js';
import Sidebar from '../../components/HomeNavBar/index';
import TimeAndDate from '../../components/DateAndTime/TimeAndDate';
import newOrderSound from '../../components/sounds/livechat.mp3';
import Annimation from '../../components/LoadingAnnimation/Annimation';
import { useNavigate } from 'react-router-dom';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import ConfigClass from "../../models/Consts/Consts.js";





import React, { useState, useEffect } from 'react';

function KitchenPage() {


    const [userId, setUserId] = useState(null);

    const [message, setMessage] = useState('');
    const [orders, setOrders] = useState([]);
    const [menuItems, setMenuItems] = useState([]);
    const [menuSections, setMenuSection] = useState([]);
    const [queriedItemSection, setQueriedItemSection] = useState('');
    const [previousOrderCount, setPreviousOrderCount] = useState(0);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const firebaseConfig = {
        apiKey: process.env.REACT_APP_API_KEY,
        authDomain: process.env.REACT_APP_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_PROJECT_ID,
        storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_APP_ID,
        measurementId: process.env.REACT_APP_MEASUREMENT_ID,
    };

    if (!getApps().length) {
        initializeApp(firebaseConfig);
    }
    const storage = getStorage();

    const audio = new Audio(newOrderSound);

    async function fetchMenuItems(userId, queriedItemSection) {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/menuItems/${userId}/${queriedItemSection}`);
        const menuItemsData = await response.json();

        //This code is reusable 
        // Download the image URLs for each menu item
        const menuItemsWithImages = await Promise.all(
            menuItemsData.map(async (menuItem) => {
                try {
                    const imageUrl = await getDownloadURL(ref(storage, menuItem.imageUrl));
                    menuItem.imageUrl = imageUrl; // Replace the imageUrl property with the downloaded image URL

                } catch (error) {
                    console.error('Error getting download URL:', error);
                    // Handle the error accordingly
                    menuItem.imageUrl = null; // or throw an error, depending on your use case
                }
                return menuItem;
            })
        );

        console.log(JSON.stringify(menuItemsWithImages));
        setMenuItems(menuItemsWithImages);
    }
    async function fetchMenuSections(userId) {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/itemSections/${userId}`);
        const menuSections = await response.json();
        console.log("Fetched menuSections:", menuSections);
        setMenuSection(menuSections);

    }

    async function fetchOrders(userId) {

        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/orderItemsByToday/${userId}`);
        const orderItems = await response.json();
        setOrders(orderItems);
        setLoading(false);
    }



    if (!getApps().length) {
        initializeApp(firebaseConfig);
    }

    const auth = getAuth();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                // User is logged in. You can update your application's state with the user object.
                setUserId(user.uid);
                fetchOrders(user.uid);

                fetchMenuItems(user.uid);
                fetchMenuSections(user.uid);

                // Fetch orders every two second
                const intervalId = setInterval(() => {
                    fetchOrders(user.uid);
                }, 10000);

                // Cleanup function that clears the interval
                return () => {
                    clearInterval(intervalId);
                };
            } else {
                // User is logged out. You can clear the user object or perform any other cleanup needed.
                console.log("User logged out!");
                setMessage("Please Login!");
            }
        });

        // Cleanup function that unsubscribes from the onAuthStateChanged listener
        return () => {
            unsubscribe();
        };
    }, []);


    useEffect(() => {


        // Check if the number of orders has increased
        if (orders.length > previousOrderCount) {
            // Play notification sound
            audio.play();
        }

        // Update the previous order count
        setPreviousOrderCount(orders.length);

    }, [orders, menuSections]);

    useEffect(() => {
        if (userId) {
            fetchMenuItems(userId, queriedItemSection);
        }
    }, [userId, queriedItemSection]);



    return (
        <div>
           
            <Sidebar />
            <div className="content-container">

                <div className="TableViewTitle">
                    <h1>Newst Orders</h1>
                    <TimeAndDate />
                </div>
                <div className="modern-container">
                    <div className="App">
                        <div className="Content">
                            {orders.sort((a, b) => {
                                const dateA = new Date(a.createdAt._seconds * 1000 + a.createdAt._nanoseconds / 1000000);
                                const dateB = new Date(b.createdAt._seconds * 1000 + b.createdAt._nanoseconds / 1000000);
                                return dateB - dateA;
                            }).map((order) => (
                                <OrderCard key={order.createdAt._seconds} orderData={order} userId={userId}/>
                            ))}
                        </div>

                    </div>

                    <div>

                    </div>
                </div>
            </div>
        </div>
    );


}
export default KitchenPage;
